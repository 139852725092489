import { AnimatePresence, motion } from "framer-motion";
import { IconLarge } from "../Icons";
import { svg_icons } from "../../../services/variant_constants";
import { useState } from "react";

export const useBottomSheet = () => {
  const [_bottomSheetVisible, _setBottomSheetVisible] = useState(false);
  const viewSheet = () => {
    _setBottomSheetVisible(true);
  };
  const hideSheet = () => {
    _setBottomSheetVisible(false);
  };

  return {
    _bottomSheetVisible: _bottomSheetVisible,
    viewSheet: viewSheet,
    hideSheet: hideSheet,
  };
};

function BottomSheet({
  header_icon,
  title,
  children,
  _bottomSheetVisible,
  hideSheet,
  styles = { container: {}, content: {} },
}) {
  return (
    <AnimatePresence>
      {_bottomSheetVisible && (
        <motion.div
          className="Bottom-sheet-container"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20,
          }}
        >
          <div
            onClick={hideSheet && hideSheet}
            className="Bottom-sheet-overlay"
          ></div>

          <motion.div
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
            }}
            className="Bottom-sheet"
            style={styles.container ?? {}}
          >
            <div className="Bottom-sheet-header">
              <p className="Bottom-sheet-header-title">{title ?? ""}</p>
              {hideSheet && (
                <IconLarge
                  onClick={hideSheet}
                  icon={header_icon ?? svg_icons.cross_icon}
                  fill="var(--color-pb-40)"
                  stroke_color="none"
                />
              )}
            </div>
            <div style={styles.content ?? {}} className="Bottom-sheet-content">{children}</div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default BottomSheet;
