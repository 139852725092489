import { affiliate_models } from "../../../../meta";
import { InputHeaderTextWithIcon } from "../../../design_system/forms/InputGroupHeader";
import { RadioInput } from "../../../design_system/forms/RadioInput";

function AffiliateModel({ register, name, disabled = false }) {
  return (
    <div
      data-disabled={disabled}
      onClick={(e) => {
        if (disabled) e.preventDefault();
      }}
      className="Affiliate-settings-input-group"
    >
      <InputHeaderTextWithIcon
        icon_props={{ fill: "none", stroke_color: "#1f1f1f" }}
      >
        Commission Type
      </InputHeaderTextWithIcon>
      <RadioInput
        classes={{ container: "mt-16" }}
        name={name}
        value={affiliate_models.cart_value}
        register={register}
      >
        On Entire Cart Value
      </RadioInput>
      <RadioInput
        classes={{ container: "mt-16" }}
        name={name}
        value={affiliate_models.single_sale}
        register={register}
      >
        On Cart Item
      </RadioInput>
    </div>
  );
}

export default AffiliateModel;
