import { Suspense, lazy } from "react";
import { Outlet, createBrowserRouter } from "react-router-dom";
import { fetchStoreProductWithParam } from "./services/firebase/fbAffiliate";
import { OutletErrorBoundary } from "./components/common/OutletErrorBoundary";
import {
  login_routes,
  product_routes,
  root_routes /* store_routes */,
  settings_routes,
  store_routes,
  welcome_routes,
} from "./utilty";

import DesignSystemRoot from "./pages/design_system/DesignSystemRoot";
import AppLayout from "./layouts/AppLayout";
import ThemeContextProvider from "./components/Providers/ViewportProvider";
import StoreDetailsPage from "./pages/affiliate/store/StoreDetailsPage";
import StoreLayout from "./layouts/StoreLayout";
import ProductDetail from "./pages/affiliate/products/ProductDetails";
import IntegrationOverview from "./pages/affiliate/api_integration/IntegrationOverview";
import GlobalAffiliateSettings from "./pages/affiliate/settings/GlobalAffiliateSettings";
import { ErrorBoundary } from "./components/common/ErrorBoundary";
import AddNewProductWithLink from "./pages/affiliate/products/AddNewProductWithLink";
import Home from "./pages/dashboard/Home";
import Login from "./pages/login/Login";
import Onboarding from "./pages/registration/Onboarding";
import BrandProfile from "./pages/registration/BrandProfile";
import WelcomeLayout from "./layouts/WelcomeLayout";
import AuthContextProvider from "./components/Providers/AuthProvider";
import ProductListingPage from "./pages/affiliate/products/ProductListingPage";
import ContinueWithEmail from "./pages/login/ContinueWithEmail";
import ValidateStore from "./components/Wrappers/ValidateStoreWrapper";
import AffiliateListing from "./pages/affiliate/affiliates/AffiliateListing";
import AffiliateLayout from "./layouts/AffiliateLayout";

import KYC from "./pages/registration/KYC";
import StoreIndex from "./pages/affiliate/store/StoreIndex";
import StoreMediaKitPage from "./pages/affiliate/store/StoreMediaKitPage";

const SettingsIndex = lazy(() =>
  import("./pages/affiliate/settings/SettingsIndex")
);
const affiliate_router_index = {
  path: "",
  element: <AffiliateListing />,
};
const affiliate_router = {
  path: root_routes.affiliates,
  errorElement: <ErrorBoundary />,
  element: (
    <ValidateStore>
      <AffiliateLayout />
    </ValidateStore>
  ),
  children: [affiliate_router_index],
};

const login_router = {
  path: root_routes.login,
  errorElement: <ErrorBoundary />,
  element: (
    <ThemeContextProvider>
      <Outlet />
    </ThemeContextProvider>
  ),
  children: [
    { path: "", element: <Login /> },
    { path: login_routes.email_link.segment, element: <ContinueWithEmail /> },
  ],
};

const welcome_router = {
  path: root_routes.welcome,
  errorElement: <ErrorBoundary />,
  children: [
    {
      path: welcome_routes.onboarding.segment,
      element: <Onboarding />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: welcome_routes.profile.segment,
      element: <BrandProfile />,
    },
  ],
  element: (
    <ThemeContextProvider>
      <AuthContextProvider>
        <WelcomeLayout disabled={true} />
      </AuthContextProvider>
    </ThemeContextProvider>
  ),
};

const dashboard_router = {
  path: root_routes.dashboard,
  element: (
    <ValidateStore>
      <Home />
    </ValidateStore>
  ),
};

const store_index_route = {
  path: "",
  element: <StoreIndex />,
};

const store_details_route = {
  path: store_routes.basic_details.segment,
  element: <StoreDetailsPage />,
};

const store_media_kit_route = {
  path: store_routes.media_kit.segment,
  element: <StoreMediaKitPage />,
};

const store_router = {
  path: root_routes.store,
  element: <StoreLayout />,
  errorElement: <ErrorBoundary />,
  children: [store_index_route, store_details_route, store_media_kit_route],
};

//#region PRODUCT ROUTER
const productDetailLoader = async ({ request }) => {
  const url = new URL(request.url);
  const product_link = url.searchParams.get("q");
  if (!product_link) return null;
  const data = await fetchStoreProductWithParam({
    param: "link",
    value: product_link,
  });
  if (data.length) return Object.values(data.products)[0];
  else
    throw new Response(product_link, {
      status: 404,
      statusText: "Product not found!",
    });
};

const products_index_route = {
  path: "",
  element: <ProductListingPage />,
};

const products_listing_route = {
  path: product_routes.list.segment,
  element: <ProductListingPage />,
};

const add_product_with_link_route = {
  path: product_routes.add_product_with_link.segment,
  element: <AddNewProductWithLink />,
};

const product_detail_route = {
  loader: productDetailLoader,
  errorElement: <OutletErrorBoundary />,
  path: product_routes.product_detail.segment,
  element: <ProductDetail />,
};

const product_detail_param_route = {
  errorElement: <OutletErrorBoundary />,
  path: product_routes.product_detail.segment + "/:product_id",
  element: <ProductDetail />,
};

const products_router = {
  path: root_routes.products,
  errorElement: <ErrorBoundary />,
  element: (
    <ValidateStore>
      <Outlet />
    </ValidateStore>
  ),
  children: [
    products_index_route,
    add_product_with_link_route,
    product_detail_route,
    product_detail_param_route,
    products_listing_route,
  ],
};
//#endregion

//#region SETTINGS ROUTER
const settings_index_router = {
  path: "",
  element: <SettingsIndex />,
};

const api_overview_router = {
  path: settings_routes.api_overview.segment,
  element: <IntegrationOverview />,
};

const global_affiliate_settings_router = {
  path: settings_routes.global_affiliate_settings.segment,
  element: <GlobalAffiliateSettings />,
};

const settings_router = {
  path: root_routes.settings,
  errorElement: <ErrorBoundary />,
  element: (
    <ValidateStore>
      <Suspense fallback={<p>Loading...</p>}>
        <Outlet />
      </Suspense>
    </ValidateStore>
  ),
  children: [
    settings_index_router,
    global_affiliate_settings_router,
    api_overview_router,
  ],
};
//#endregion

const kyc_router = {
  path: "/kyc",
  element: <KYC />,
};

const brand_app_router = {
  path: "/",
  errorElement: <ErrorBoundary />,
  element: (
    <ThemeContextProvider>
      <AuthContextProvider>
        <AppLayout />
      </AuthContextProvider>
    </ThemeContextProvider>
  ),
  children: [
    dashboard_router,
    store_router,
    products_router,
    settings_router,
    affiliate_router,
    kyc_router,
  ],
};

const design_system_router = {
  path: root_routes.design_system,
  element: <DesignSystemRoot />,
};

export const router = createBrowserRouter([
  login_router,
  welcome_router,
  brand_app_router,
  design_system_router,
]);
