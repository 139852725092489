import { createSlice } from "@reduxjs/toolkit";

const initial_state = {
  id: null,
  link: null,
  api_integrated: false,
  store_details: {
    name: "",
    is_store_adult: false,
    banner: null,
    logo: null,
    description: "",
    media_items: [],
  },
};

const storeGlobalSettingsReducer = {
  saveGlobalSettings: (state, action) => {
    return { ...state, global_settings: { ...action.payload } };
  },
};

const storeDetailsReducer = {
  initStore: (state, action) => {
    return { ...state, ...action.payload };
  },
  clearStore: () => {
    return initial_state;
  },
  saveStoreDetails: (state, action) => {
    return {
      ...state,
      link: action.payload.link ?? state.link,
      store_details: { ...state.store_details, ...action.payload },
    };
  },
};

const storeSlice = createSlice({
  initialState: initial_state,
  name: "brand_store",
  reducers: {
    ...storeDetailsReducer,
    ...storeGlobalSettingsReducer,
  },
});

export const { initStore, clearStore, saveStoreDetails, saveGlobalSettings } =
  storeSlice.actions;
export default storeSlice.reducer;
