import { InputHeaderTextWithIcon } from "../../../design_system/forms/InputGroupHeader";
import ToggleInput from "../../../design_system/forms/ToggleInput";

function AffiliateProgramScope({ register, name = "is_store_wide" }) {
  return (
    <div className="Affiliate-settings-input-group">
      <InputHeaderTextWithIcon
        classes={{ container: "mb-16" }}
        icon_props={{ fill: "none", stroke_color: "#1f1f1f" }}
      >
        Affiliate Program Scope
      </InputHeaderTextWithIcon>

      <ToggleInput register={register} name={name} classes={"mt-24"}>
        Run Store Wide Affiliate Program
      </ToggleInput>
    </div>
  );
}

export default AffiliateProgramScope;
