import { configureStore } from "@reduxjs/toolkit";
import brandReducer from "./brandSlice";
import storeReducer from "./storeSlice";
import productReducer from "./productSlice";
import affiliateReducer from "./affiliateSlice";

const rootReducer = {
  brand: brandReducer,
  brand_store: storeReducer,
  store_products: productReducer,
  affiliates: affiliateReducer,
};

export const reduxStore = configureStore({ reducer: rootReducer });
