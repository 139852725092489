import { useRouteError } from "react-router";
import ViewportContextProvider from "../Providers/ViewportProvider";
import { TextButtonMedium } from "../design_system/Buttons";

export function OutletErrorBoundary() {
  const error = useRouteError();
  console.log(error);
  return (
    <ViewportContextProvider>
      <div className="height-full flex-column flex-align-center gap-10">
        <img
          src="https://68.media.tumblr.com/ed4e042a6f238dc32f03193f761e8421/tumblr_o9qdwlvRQv1tdxtc7o1_500.gif"
          alt="sad cloud"
        />
        <h1
          style={{ fontSize: "100px", marginTop: "-100px" }}
          className="fc-accent fw-800"
        >
          {error?.status ?? 400}
        </h1>
        <p className="SH2">Oops! {error.statusText} 😭</p>
        <div>
          <TextButtonMedium
            classes="Btn-primary"
            styles={{ display: "inline", width: "156px" }}
          >
            Go Back
          </TextButtonMedium>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <TextButtonMedium styles={{ display: "inline" }}>
            Report Issue
          </TextButtonMedium>
        </div>
      </div>
    </ViewportContextProvider>
  );
}
