import { useSelector } from "react-redux";
import BottomSheet from "../design_system/bottom_sheets/BottomSheet";
import { SH2 } from "../design_system/text/SubHeading";
import { TextButtonMedium } from "../design_system/Buttons";
import { useNavigate } from "react-router";
import { store_routes } from "../../utilty";

function ValidateStore({ children }) {
  const store_created = useSelector(
    (state) => state.brand.affiliate.store_created
  );
  const navigate = useNavigate();

  if (!store_created)
    return (
      <BottomSheet _bottomSheetVisible={true} title={"Oops..!"}>
        <SH2>Please create a store to access this</SH2>
        <TextButtonMedium
          onClick={() => navigate(store_routes.basic_details.link)}
          classes="Btn-accent Btn-full mt-24"
        >
          Let's Create a Store
        </TextButtonMedium>
      </BottomSheet>
    );
  return children;
}

export default ValidateStore;
