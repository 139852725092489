import { useQuill } from "react-quilljs";
import InputError from "../../../design_system/forms/InputError";
import InputLabelText from "../../../design_system/forms/InputLabels";
import InputFieldArea from "../../../design_system/forms/InputFieldArea";

function StoreDescription({
  initial_value,
  setValue = () => {},
  name = "description",
  error,
  setError = () => {},
  resetError = () => {},
}) {
  const { quill, quillRef } = useQuill({
    placeholder: "Write here...",
    modules: {
      toolbar: [
        [
          "bold",
          "italic",
          "underline",
          { list: "ordered" },
          { list: "bullet" },
          { align: [] },
        ],
      ],
    },
  });

  return (
    <div>
      <InputLabelText>Tell us more about your store</InputLabelText>
      <InputFieldArea
        name={name}
        quill={quill}
        quillRef={quillRef}
        initial_value={initial_value}
        setValue={setValue}
        error={error}
        setError={setError}
        resetError={resetError}
      />
      {/* <textarea
        style={{ height: "80px" }}
        className="Input-text Input-text-area"
        placeholder={
          "What are your products about? What industry are you in? etc.."
        }
        {...register(name)}
      /> */}
      <InputError>{error}</InputError>
    </div>
  );
}

export default StoreDescription;
