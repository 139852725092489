import { useState } from "react";
import {
  commission_disbursement_periodicity,
  commission_disbursement_time_values,
} from "../../../../meta";
import Dropdown from "../../../design_system/forms/Dropdown";
import { InputHeaderTextWithIcon } from "../../../design_system/forms/InputGroupHeader";
import Tip from "../../../design_system/Tips";
import { P2 } from "../../../design_system/text/BodyText";

function CommissionDisbursement({
  register,
  disabled,
  default_value = {
    time_unit: commission_disbursement_periodicity.monthly,
    time: null,
  },
  name = { time: "time", time_unit: "time_unit" },
}) {
  const [_currentTimeUnit, _setCurrentTimeUnit] = useState(
    default_value.time_unit
  );

  const cycle_options = [
    { value: commission_disbursement_periodicity.weekly, content: "Weekly" },
    { value: commission_disbursement_periodicity.monthly, content: "Monthly" },
  ];

  const cycle_disclaimer = _currentTimeUnit === commission_disbursement_periodicity.weekly ?
    "A bill will be raised to the brand on every Saturday for all the qualified sales made in the previous 7 days. The brand can fulfil the payment within 48 hours of receiving the bill."
    : "A bill will be raised to the brand every end of the month for all the qualified sales of that month. The brand can fulfil the payment within 48 hours of receiving the bill."

  return (
    <div data-disabled={disabled} className="Affiliate-settings-input-group">
      <InputHeaderTextWithIcon classes={{ container: "mb-16" }}>
        Commission Disbursement
      </InputHeaderTextWithIcon>
      {/*  <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          columnGap: "8px",
        }}
      > */}
      <Dropdown
        disabled={disabled}
        menu_title={"Payment Cycle"}
        options={cycle_options}
        register={register}
        name={name.time_unit}
        onOptionSelect={(option_value) => _setCurrentTimeUnit(option_value)}
        default_value={
          cycle_options.filter(
            (option) => option.value === _currentTimeUnit
          )[0]
        }
      />

      <P2 styles={{ paddingLeft: "6px" }} classes="mt-8 fc-green">{cycle_disclaimer}</P2>
      {/*  <Dropdown
          disabled={disabled}
          menu_title={"Payment Day"}
          options={commission_disbursement_time_values[_currentTimeUnit]}
          default_value={
            commission_disbursement_time_values[_currentTimeUnit].filter(
              (option) =>
                option.value.toString() === default_value.time.toString()
            )[0]
          }
          register={register}
          name={name.time}
        /> */}
      {/*    </div> */}
    </div>
  );
}

export default CommissionDisbursement;
