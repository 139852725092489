export function RadioInput({
  disabled = false,
  type,
  selected_option = null,
  children,
  name,
  value,
  register = () => {},
  classes = { container: "", label: "", input: "" },
  styles = { container: {}, label: {}, input: {} },
  onClick = () => {},
}) {
  if (disabled)
    return (
      <div
        style={{ opacity: "40%", ...styles.container }}
        className={`Radio-input ${classes.container}`}
      >
        <input disabled type="radio" />
        <label>{children}</label>
      </div>
    );

  if (type === "dropdown")
    return (
      <div
        style={styles.container}
        className={`Radio-input ${classes.container}`}
      >
        <input
          onClick={onClick}
          type="radio"
          value={value}
          id={value}
          name={name}
          hidden
          {...register(name)}
        />
        <label
          style={{ width: "100%", height: "100%" }}
          className={`${
            selected_option?.value === value ? "fc-accent fw-600" : ""
          }`}
          htmlFor={value}
        >
          {children}
        </label>
      </div>
    );

  return (
    <div
      style={styles.container}
      className={`Radio-input ${classes.container}`}
    >
      <input
        style={styles.input}
        className={`${classes.input}`}
        onClick={onClick}
        type="radio"
        value={value}
        id={value}
        {...register(name)}
      />
      <label
        style={styles.label}
        className={`${classes.label}`}
        htmlFor={value}
      >
        {children}
      </label>
    </div>
  );
}
