import { IconButtonMedium } from "../Buttons";
import { SH2, SH3 } from "../text/SubHeading";

export function OptionWithIcon({
  custom = false,
  styles = { title: {}, subtitle: {}, list_item: {}, button: {} },
  classes = {
    title: "fw-500",
    subtitle: "fc-pb-80",
    list_item: "",
    button: "Btn-primary",
  },
  onClick,
  title,
  subtitle,
  icon,
  icon_color = "none",
  icon_fill = "#fff",
  counter,
  children,
}) {
  if (!custom)
    return (
      <li
        className={classes.list_item}
        style={styles.list_item}
        onClick={onClick}
      >
        <SH2 styles={styles.title} classes={classes.title}>
          {title}
        </SH2>
        <SH3 styles={styles.subtitle} classes={classes.subtitle}>
          {subtitle}
        </SH3>
        {counter && <SH2>{counter}</SH2>}
        <IconButtonMedium
          onClick={onClick}
          styles={styles.button}
          classes={classes.button}
          icon={icon}
          icon_color={icon_color}
          icon_fill={icon_fill}
        />
      </li>
    );
  if (custom) return <>{children}</>;
}

export function OptionsFlex() {
  return <li></li>;
}
