import btn_google_sign_in from "../../assets/svg/graphics/btn_google_sign_in.svg";
import WithLoader from "../../components/Wrappers/LoaderWrapper";
import {
  makeNewBrand,
  makeNewEmailAuth,
  makeNewUser,
  makeNewUserProfile,
} from "../../schema/users_schema";
import {
  findBrandByEmail,
  findUserByEmail,
  signUpWithGoogle,
} from "../../services/firebase/fbAuth";

function ContinueWithGoogle({ _withLoader, authSuccesful }) {
  const initAuth = async () => {
    _withLoader.startLoading();

    try {
      const auth_user = await signUpWithGoogle();
      const auth = makeNewEmailAuth({
        id: auth_user.email,
        uid: auth_user.uid,
      });

      const new_user_profile = makeNewUserProfile({
        first_name: auth_user.displayName.split(" ")[0],
        last_name: auth_user.displayName.split(" ")[0],
        photo: auth_user.photoURL,
        email: auth_user.email,
        is_email_verified: true,
      });

      const brand = await findBrandByEmail({ email: auth.email.id });
      if (brand)
        authSuccesful({ brand_exists: true, user_exists: true, brand: brand });
      else {
        const user = await findUserByEmail({ email: auth.email.id });

        if (user) {
          const new_brand = makeNewBrand({ auth: auth, id: user.id });
          authSuccesful({
            brand_exists: false,
            user_exists: true,
            user: user,
            brand: new_brand,
          });
        } else {
          const new_user = makeNewUser({
            profile: new_user_profile,
            auth: auth,
          });
          authSuccesful({
            brand_exists: false,
            user_exists: false,
            user: new_user,
          });
        }
      }
    } catch (err) {
      _withLoader.endLoading();
      _withLoader.setError(err.message);
    }
  };

  return (
    <div className="Social-signin-container">
      <WithLoader {..._withLoader} classes={"mt-24"}>
        <button
          style={{ justifyContent: "center" }}
          onClick={initAuth}
          className="google-signin-btn"
        >
          <img
            style={{ marginRight: "4px" }}
            src={btn_google_sign_in}
            alt="google sign in"
          />
          <span>Continue with Google</span>
        </button>
      </WithLoader>
    </div>
  );
}
export default ContinueWithGoogle;
