export const affiliate_entry_types = {
  open_for_all: "open_for_all",
};

export const affiliate_models = {
  cart_value: "cart_value",
  single_sale: "single_sale",
};

export const commission_types = {
  fixed_percent: "fixed_percent",
  fixed_amount: "fixed_amount",
};

export const commission_disbursement_periodicity = {
  monthly: "monthly",
  weekly: "weekly",
};

export const affiliate_models_options = {
  [affiliate_models.cart_value]: "On Cart Value",
  [affiliate_models.single_sale]: "On Item Sale",
};

export const commission_types_options = {
  [commission_types.fixed_percent]: "Fixed %",
  [commission_types.fixed_amount]: "Fixed Amount",
};

export const commission_disbursement_time_values = {
  monthly: Array.from({ length: 28 }, (v, k) => {
    return {
      value: k + 1,
      content:
        k +
        1 +
        `${
          k + 1 === 1 ? "st" : k + 1 === 2 ? "nd" : k + 1 === 3 ? "rd" : "th"
        } Day`,
    };
  }),
  weekly: [
    { content: "Monday", value: 1 },
    { content: "Tuesday", value: 2 },
    { content: "Wednesday", value: 3 },
    { content: "Thursday", value: 4 },
    { content: "Friday", value: 5 },
    { content: "Saturday", value: 6 },
    { content: "Sunday", value: 7 },
  ],
};
