//import { IconButtonMedium } from "../../design_system/Buttons";
import { svg_icons } from "../../../../services/variant_constants";
import { SH3 } from "../../../design_system/text/SubHeading";
import { SVGIcon } from "../../../../assets/svg/icons/res";
import { useBottomSheet } from "../../../design_system/bottom_sheets/BottomSheet";
import UploadImageSheet from "../../../design_system/bottom_sheets/UploadImageSheet";
import { useState } from "react";
import { motion } from "framer-motion";
import { framer_configs } from "../../../../utilty";
import { useSelector } from "react-redux";
import { useImageStorage } from "../../../../hooks/UploadImageFBHook";
import { getStoreBannerStorage } from "../../../../services/firebase";
import UploadingLoaderSheet from "../../../design_system/bottom_sheets/UploadingLoaderSheet";

function StoreDetailsBanner({ Ref }) {
  const brand_id = useSelector((state) => state.brand.id);

  const uploadBottomSheet = useBottomSheet();

  const [_banner, _setBanner] = useState(
    useSelector((state) => state.brand_store.store_details?.banner) ?? null
  );

  const setAfterUpload = (url) => {
    Ref.current = url;
    _setBanner(url);
  };
  const _uploadToStorage = useImageStorage({
    config: {
      folder: getStoreBannerStorage({ brand: brand_id }),
      taskAfterUpload: setAfterUpload,
    },
  });

  const confirmBannerUpload = (banner) => {
    uploadBottomSheet.hideSheet();
    if (banner.file) {
      _uploadToStorage.startUpload(banner.file);
    } else {
      setAfterUpload(banner.url);
    }
  };

  return (
    <>
      {uploadBottomSheet._bottomSheetVisible && (
        <UploadImageSheet
          getImage={confirmBannerUpload}
          sheet_props={{ ...uploadBottomSheet }}
          image_width={"200"}
          image_height={"400"}
          image_size={400}
          styles={{
            preview_image: {
              width: "100%",
              aspectRatio: 2 / 1,
              borderRadius: "8px",
            },
          }}
        />
      )}
      {_uploadToStorage._isUploading && <UploadingLoaderSheet />}
      {!_banner ? (
        <motion.div
          variants={{ ...framer_configs.variants.bounce_up }}
          onClick={uploadBottomSheet.viewSheet}
          className="Store-details-banner image-upload-action"
        >
          <SVGIcon icon={svg_icons.upload_icon} size="30" />
          <SH3>Store Banner</SH3>
        </motion.div>
      ) : (
        <motion.img
          variants={{ ...framer_configs.variants.bounce_up }}
          onClick={uploadBottomSheet.viewSheet}
          className="Store-details-banner cursor-pointer"
          src={_banner}
          alt="Banner"
        />
      )}
    </>
  );
}
export default StoreDetailsBanner;
