import { createSlice } from "@reduxjs/toolkit";

const initial_state = {};

const affiliateSlice = createSlice({
  name: "affiliates",
  initialState: initial_state,
  reducers: {
    initAffiliates: (state, action) => {
      return { ...action.payload };
    },
    clearAffiliates: () => {
      return {};
    },
  },
});
export const { initAffiliates, clearAffiliates } = affiliateSlice.actions;
export default affiliateSlice.reducer;
