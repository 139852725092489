import DashboardHomeHeader from "../../components/affiliate/dashboard/DashboardHomeHeader";
import RevenueChart from "../../components/affiliate/dashboard/RevenueChart";
import StatCard from "../../components/affiliate/dashboard/StatCard";
import ProductListing from "../../components/affiliate/products/ProductListing";
import { TextIconButtonMedium } from "../../components/design_system/Buttons";
import { H2 } from "../../components/design_system/text/Heading";
import { SH3 } from "../../components/design_system/text/SubHeading";
import { svg_icons } from "../../services/variant_constants";
import { motion } from "framer-motion";
import { framer_configs } from "../../utilty";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
//import IntegrationOverview from "../affiliate/api_integration/IntegrationOverview";

function Home() {
  const commission_due = useSelector(
    (state) => state.brand_store.counters.total_commissions_due ?? 0
  );
  const stats = useSelector((state) => state.brand_store.stats ?? {});
  const [searchParams] = useSearchParams();
  const current_filter = searchParams.get("time") ?? "active";
  console.log(current_filter);

  return (
    <motion.div
      {...framer_configs.parent_framer_animations.stagger}
      className="Dashboard-page"
    >
      <DashboardHomeHeader />
      <StatCard
        classes={{ container: "Revenue-stat", title: "fc-accent" }}
        title={"Revenue"}
        growth={0}
        stat={`Rs. ${(stats[current_filter] && stats[current_filter].sales_amount) ?? 0
          }`}
      />
      <StatCard
        classes={{ container: "Item-sold-stat" }}
        growth={0}
        title={"Items Sold"}
        stat={(stats[current_filter] && stats[current_filter].sales.toString()) ?? "0"}
      />
      <div className="Revenue-chart">
        <RevenueChart />
      </div>
      <div className="Commission-banner">
        <div>
          <SH3 classes="fw-400">Upcoming Commissions</SH3>
          <H2 classes="fw-500">Rs. {commission_due}</H2>
        </div>
        {/*  <TextIconButtonMedium
          classes="Btn-tertiary"
          icon={svg_icons.right_arrow_icon}
          icon_fill={"#000"}
          icon_stroke={"none"}
          styles={{ backgroundColor: "#fff", borderRadius: "12px" }}
        >
          View and Pay
        </TextIconButtonMedium> */}
      </div>
      <div className="Product-list">
        <ProductListing />
      </div>

    </motion.div>
  );
}

export default Home;
