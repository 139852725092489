import { cloudAxios } from "./axiosInterface";
import { meta_scraping_routes } from "./constants";

export async function scrapeBrandProductDetail(url) {
  console.log(url);
  const instance = cloudAxios;
  try {
    const details = await instance.get(
      meta_scraping_routes.get_brand_product_meta,
      { params: { url: url } }
    );
    if (details.data.error) throw Error(details.data.message);
    return details.data;
  } catch (err) {
    console.log(err.message);
    return { error: true, message: err.message };
  }
}
