export const firebase_collections = {
  users: "users",
  brands: "brands",
  affiliate_stores: "affiliate_stores",
  affiliate_products: "affiliate_products",
  affiliations: "affiliations",
};

export const errors = {
  default: { code: 500, error: true, message: "Oops! Something went wrong" },
};

const emSignerPayload = {
  Name: "Diksha Giri",
  FileType: "PDF",
  SignatureType: 3,
  SelectPage: "SPECIFY",
  SignaturePosition: "Middle-Left",
  AuthToken: "7c51ed8c-89f4-4390-a5ff-dcbcbd2c8e55",
  File: "",
  PageNumber: "8,12,14",
  PreviewRequired: true,
  PagelevelCoordinates: null,
  CustomizeCoordinates: null,
  SUrl: "http://localhost:3000/Success",
  FUrl: "http://localhost:3000/Error",
  CUrl: "http://localhost:3000/Cancel",
  ReferenceNumber: "REFDB100011",
  Enableuploadsignature: true,
  Enablefontsignature: true,
  EnableDrawSignature: true,
  EnableeSignaturePad: true,
  IsCompressed: false,
  IsCosign: false,
  EnableViewDocumentLink: true,
  Storetodb: true,
  IsGSTN: true,
  IsGSTN3B: false,
};
