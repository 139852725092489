import { useEffect, useState } from "react";
import BottomSheet, {
  useBottomSheet,
} from "../design_system/bottom_sheets/BottomSheet";
import SuccessFrill from "../../assets/svg/graphics/blue_frill_tick.svg";

export const useWithLoader = (initial_state = false) => {
  const [_loading, _setLoading] = useState(initial_state);
  const [_error, _setError] = useState(false);

  const startLoading = () => {
    console.log("Starting loader");
    _setLoading(true);
  };
  const endLoading = () => {
    console.log("Stopping loader");
    _setLoading(false);
  };

  const toggleLoading = () => {
    _setLoading(!_loading);
  };

  const setError = (error) => {
    console.log("setting loader error");
    _setError(error);
  };

  return {
    _loading,
    startLoading,
    endLoading,
    toggleLoading,
    setError,
    _error,
  };
};

function WithLoader({
  classes,
  _loading,
  _error,
  enableSuccessPop = false,
  SuccessSheet = () => {
    return (
      <div className="text-align-center">
        <img src={SuccessFrill} alt="Success" />
      </div>
    );
  },
  FailureSheet = () => {
    return <p className="text-align-left">{_error}</p>;
  },
  Loader = () => {
    return <p>Please Wait...</p>;
  },
  children,
}) {
  const successSheet = useBottomSheet();
  const failureSheet = useBottomSheet();

  const [_oldLoadingState, _setOldLoadingState] = useState(_loading);

  useEffect(() => {
    console.log("Loading from wrapper ", _loading);
  });

  useEffect(() => {
    if (_loading) {
      console.log("Setting old loading state to true");
      _setOldLoadingState(true);
    }
  }, [_loading]);

  useEffect(() => {
    if (
      _oldLoadingState !== _loading &&
      _error &&
      !failureSheet._bottomSheetVisible
    ) {
      console.log(_error);
      failureSheet.viewSheet();
      _setOldLoadingState(false);
    }
    if (
      enableSuccessPop &&
      _oldLoadingState !== _loading &&
      !_loading &&
      !_error
    ) {
      console.log("Success");
      successSheet.viewSheet();
      _setOldLoadingState(false);
      return () =>
        setTimeout(() => {
          successSheet.hideSheet();
        }, 1000);
    }
  }, [
    enableSuccessPop,
    successSheet,
    failureSheet,
    _loading,
    _oldLoadingState,
    _error,
  ]);

  if (_loading)
    return (
      <div className={classes}>
        <Loader />
      </div>
    );
  return (
    <>
      <BottomSheet title={"Success!"} {...successSheet}>
        <SuccessSheet />
      </BottomSheet>
      <BottomSheet title={"Oops!"} {...failureSheet}>
        <FailureSheet />
      </BottomSheet>
      {children}
    </>
  );
}

export default WithLoader;
