import { svg_icons } from "../../../services/variant_constants";
import { TextIconButtonMedium } from "../Buttons";
import { SH2 } from "../text/SubHeading";
import BottomSheet from "./BottomSheet";

function DiscardSheet({
  sheet_props,
  handleConfirm = () => {},
  handleCancel = () => {},
  message = (
    <SH2>
      Are you sure you want to discard your changes? You will{" "}
      <span className="fw-600">lose your progress if you continue.</span>
    </SH2>
  ),
}) {
  return (
    <BottomSheet title={"Confirm Discard"} {...sheet_props}>
      {message}
      <div className="Inline-btns mt-12">
        <TextIconButtonMedium
          onClick={handleCancel}
          icon={svg_icons.cross_icon}
          icon_position="left"
          icon_stroke={"none"}
          icon_fill={"#1f1f1f"}
        >
          Cancel
        </TextIconButtonMedium>
        <TextIconButtonMedium
          onClick={handleConfirm}
          icon={svg_icons.trash_icon}
          classes="Btn-danger"
          icon_stroke={"var(--color-red-solid)"}
        >
          Continue
        </TextIconButtonMedium>
      </div>
    </BottomSheet>
  );
}

export default DiscardSheet;
