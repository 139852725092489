import { useDispatch, useSelector } from "react-redux";
import { svg_icons } from "../../../services/variant_constants";
import { TextIconButtonMedium } from "../../design_system/Buttons";
import { initStore, saveStoreDetails } from "../../../store/storeSlice";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router";
import { framer_configs, settings_routes } from "../../../utilty";
import BottomSheet, {
  useBottomSheet,
} from "../../design_system/bottom_sheets/BottomSheet";
import { SH2 } from "../../design_system/text/SubHeading";
import {
  makeNewStoreDetails,
  makeNewStore,
} from "../../../schema/affiliate_schema";
import {
  createStore,
  updateStore,
} from "../../../services/firebase/fbAffiliate";
import { motion } from "framer-motion";
import StoreDetailsBanner from "./store_details_form/StoreDetailsBanner";
import StoreDetailsLogo from "./store_details_form/StoreDetailsLogo";
import StoreLink from "./store_details_form/StoreLink";
import StoreName from "./store_details_form/StoreName";
import StoreDescription from "./store_details_form/StoreDescription";
import { useRef } from "react";

import { completeStoreCreation } from "../../../store/brandSlice";
import WithLoader, { useWithLoader } from "../../Wrappers/LoaderWrapper";
import AdultDisclaimer from "./store_details_form/AdultDisclaimer";

const detail_schema = yup.object().shape({
  link: yup
    .string()
    .url("Please enter a valid link")
    .required("Please enter your store link"),
  name: yup
    .string()
    .max(20, "Maximum 20 characters")
    .required("Please fill your store or brand name"),
  description: yup
    .string()
    .min(20, "Description must be at least 20 characters")
    .required("Please fill a description"),
  audience_restriction: yup.string().required("Choose an option for age restriction")

});

function StoreDetailsForm() {
  const loader = useWithLoader(false);
  const ref_banner = useRef(
    useSelector((state) => state.brand_store.store_details?.banner) ?? null
  );
  const ref_logo = useRef(
    useSelector((state) => state.brand_store.store_details?.logo) ?? null
  );

  //APIintegration used to ensure link doesn't change after api has been integrated
  const is_api_integrated = useSelector(
    (state) => state.brand_store.api_integrated
  );
  const store_link = useSelector((state) => state.brand_store.link) ?? null;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const congratsSheet = useBottomSheet();
  const brand_id = useSelector((state) => state.brand.id);
  const {
    id,
    link,
    store_details,
  } = useSelector((state) => state.brand_store);

  const { name, logo, banner, description } = store_details;
  const audience_restriction = store_details.audience_restriction ?? "none";

  const validateImages = () => {
    if ((!ref_banner.current || !ref_logo.current) && (!banner || !logo))
      alert(
        `Please upload the following: ${ref_logo.current ? "" : "\na logo for your store"
        } ${ref_banner.current ? "" : "\na banner for your store"}`
      );
  };

  const submitStoreDetails = async (data) => {
    //console.log(data);
    loader.startLoading();
    loader.setError(false);

    const { name, link, description, audience_restriction } = data;
    const store_details = makeNewStoreDetails({
      name,
      description,
      banner: ref_banner.current,
      logo: ref_logo.current,
      audience_restriction,
    });
    const store = makeNewStore({
      id: id,
      brand: brand_id,
      link,
      store_details,
    });
    if (id) {
      const updated_store = await updateStore({
        id,
        link: is_api_integrated ? store_link : link, //change link only if api hasn't been integrated
        store_details,
      });
      if (updated_store.error) {
        loader.endLoading();
        loader.setError(updated_store.message);
        return 0;
      } else {
        loader.endLoading();
        dispatch(saveStoreDetails(updated_store));
        return 0;
      }
    } else {
      const created_store = await createStore({ store });
      if (created_store.error) {
        loader.endLoading();
        loader.setError(created_store.message);
        return 0;
      } else {
        dispatch(completeStoreCreation());
        dispatch(initStore({ ...created_store }));
        loader.endLoading();
        congratsSheet.viewSheet();
      }
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(detail_schema),
    defaultValues: { banner, logo, name, link, description, audience_restriction },
  });

  return (
    <>
      <BottomSheet {...congratsSheet} title={"Great!"} hideSheet={false}>
        <SH2 classes="text-align-left">
          Your store front has been set up! <br />
          Now let's fill in some details about your affiliate program
        </SH2>
        <TextIconButtonMedium
          classes="Btn-accent Btn-full mt-32"
          onClick={() =>
            navigate(settings_routes.global_affiliate_settings.link)
          }
        >
          Fill Affiliate Program Details
        </TextIconButtonMedium>
      </BottomSheet>

      <StoreDetailsBanner Ref={ref_banner} />
      <StoreDetailsLogo Ref={ref_logo} />
      <motion.form
        variants={{ ...framer_configs.variants.bounce_up }}
        onSubmit={handleSubmit(submitStoreDetails)}
        className="Store-details-form"
      >
        <StoreLink
          disabled={is_api_integrated}
          register={register}
          name={"link"}
          error={errors.link?.message}
        />
        <StoreName
          register={register}
          name={"name"}
          error={errors.name?.message}
        />
        <StoreDescription
          initial_value={description}
          setValue={setValue}
          register={register}
          name={"description"}
          error={errors.description?.message}
          setError={setError}
          resetError={clearErrors}
        />
        <AdultDisclaimer register={register} name="audience_restriction" error={errors.audience_restriction} />
        <WithLoader
          classes="text-align-center"
          _loading={loader._loading}
          _error={loader._error}
          enableSuccessPop={true}
        >
          <TextIconButtonMedium
            classes="Btn-full Btn-accent mt-32"
            icon={id ? svg_icons.save_icon : svg_icons.round_tick}
            icon_stroke={"#fff"}
            onClick={() => {
              validateImages();
            }}
            type="submit"
          >
            {id ? "Save Store Details" : "Let's Get Started"}
          </TextIconButtonMedium>
        </WithLoader>
      </motion.form>
    </>
  );
}

export default StoreDetailsForm;
