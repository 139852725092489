import AffiliateList from "../../../components/affiliate/affiliates/AffiliateList";
import { H2 } from "../../../components/design_system/text/Heading";

function AffiliateListing() {
  return (
    <div className="Affiliate-listing-page">
      <H2 classes="mb-24">Affiliates</H2>
      <AffiliateList />
    </div>
  );
}

export default AffiliateListing;
