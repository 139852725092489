import {
  IconButtonMedium,
  TextButtonMedium,
  TextIconButtonMedium,
} from "../../components/design_system/Buttons";
import { svg_icons } from "../../services/variant_constants";

function DesignSystemButtons() {
  return (
    <>
      <div>
        <IconButtonMedium
          classes="Btn-primary"
          icon={svg_icons.round_tick}
          icon_stroke={"#fff"}
        />
        <TextButtonMedium classes="Btn-primary">Button</TextButtonMedium>
        <TextIconButtonMedium
          icon={svg_icons.round_tick}
          icon_stroke="#fff"
          icon_position="right"
          classes="Btn-primary"
        >
          Button
        </TextIconButtonMedium>
        <TextIconButtonMedium
          icon={svg_icons.round_tick}
          icon_stroke="#fff"
          icon_position="left"
          classes="Btn-primary"
        >
          Button
        </TextIconButtonMedium>
      </div>

      <div>
        <IconButtonMedium
          classes="Btn-secondary"
          icon={svg_icons.round_tick}
          icon_stroke={"#1f1f1f"}
        />
        <TextButtonMedium classes="Btn-secondary">Button</TextButtonMedium>
        <TextIconButtonMedium
          icon={svg_icons.round_tick}
          icon_stroke="#1f1f1f"
          icon_position="right"
          classes="Btn-secondary"
        >
          Button
        </TextIconButtonMedium>
        <TextIconButtonMedium
          icon={svg_icons.round_tick}
          icon_stroke="#1f1f1f"
          icon_position="left"
          classes="Btn-secondary"
        >
          Button
        </TextIconButtonMedium>
      </div>

      <div>
        <IconButtonMedium
          classes="Btn-tertiary"
          icon={svg_icons.round_tick}
          icon_stroke={"#1f1f1f"}
        />
        <TextButtonMedium classes="Btn-tertiary">Button</TextButtonMedium>
        <TextIconButtonMedium
          icon={svg_icons.round_tick}
          icon_stroke="#1f1f1f"
          icon_position="right"
          classes="Btn-tertiary"
        >
          Button
        </TextIconButtonMedium>
        <TextIconButtonMedium
          icon={svg_icons.round_tick}
          icon_stroke="#1f1f1f"
          icon_position="left"
          classes="Btn-tertiary"
        >
          Button
        </TextIconButtonMedium>
      </div>

      <div>
        <IconButtonMedium
          classes="Btn-accent"
          icon={svg_icons.round_tick}
          icon_stroke={"#fff"}
        />
        <TextButtonMedium classes="Btn-accent">Button</TextButtonMedium>
        <TextIconButtonMedium
          icon={svg_icons.round_tick}
          icon_stroke="#fff"
          icon_position="right"
          classes="Btn-accent"
        >
          Button
        </TextIconButtonMedium>
        <TextIconButtonMedium
          icon={svg_icons.round_tick}
          icon_stroke="#fff"
          icon_position="left"
          classes="Btn-accent"
        >
          Button
        </TextIconButtonMedium>
      </div>
    </>
  );
}

export default DesignSystemButtons;
