import { IconButtonMedium } from "../../components/design_system/Buttons";
import BottomSheet from "../../components/design_system/bottom_sheets/BottomSheet";
import { SH3 } from "../../components/design_system/text/SubHeading";
import { svg_icons } from "../../services/variant_constants";

function ContinueWithEmail() {
  const stored_email = localStorage.getItem("link_auth_email");
  const handleEmailSubmit = (e) => {
    e.preventDefault();
    console.log(e.target[0].value);
  };

  if (!stored_email)
    return (
      <BottomSheet _bottomSheetVisible={true} title={"Enter your email"}>
        <SH3>
          To continue please confirm your email on which you received the login
          link.
        </SH3>
        <form
          style={{ display: "flex", alignItems: "center", gap: 10 }}
          onSubmit={handleEmailSubmit}
        >
          <input placeholder="Email" type="text" className="Input-text" />
          <IconButtonMedium
            styles={{ minWidth: "var(--width-button-medium)" }}
            classes="Btn-accent"
            icon={svg_icons.right_arrow_icon}
            icon_stroke={"none"}
            icon_fill={"#fff"}
            type="submit"
          />
        </form>
      </BottomSheet>
    );
  return <SH3>Redirecting you to Brand Dashboard...</SH3>;
}

export default ContinueWithEmail;
