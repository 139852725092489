import { is_viewport_mobile } from "../../services/common";
import { framer_configs } from "../../utilty";
import { IconMedium, IconSmall } from "./Icons";
import { motion } from "framer-motion";
export function IconButtonMedium({
  onClick,
  classes = "",
  styles = {},
  icon,
  icon_stroke,
  icon_fill,
  form = null,
  type = "button",
}) {
  return (
    <motion.button
      whileHover={{
        scale: 1.05,
        transition: { type: "spring", bounce: 0, stiffness: 260, damping: 20 },
      }}
      whileTap={{ scale: 0.97 }}
      form={form}
      type={type}
      style={{ ...styles }}
      onClick={onClick}
      className={`${classes} Btn Btn-icon-medium Btn-icon`}
    >
      {is_viewport_mobile ? (
        <IconSmall style={{ cursor: "pointer" }} icon={icon} stroke_color={icon_stroke} fill={icon_fill} />
      ) : (
        <IconMedium style={{ cursor: "pointer" }} icon={icon} stroke_color={icon_stroke} fill={icon_fill} />
      )}
    </motion.button>
  );
}

export function TextButtonMedium({
  onClick,
  classes = "",
  styles = {},
  children,
  form = null,
  type = "button",
}) {
  return (
    <motion.button
      {...framer_configs.button_scale_animation}
      form={form}
      type={type}
      style={{ ...styles }}
      onClick={onClick}
      className={`${classes} Btn Btn-text Btn-text-medium`}
    >
      {children}
    </motion.button>
  );
}

export function TextIconButtonMedium({
  onClick,
  classes = "",
  styles = {},
  children,
  icon,
  icon_stroke,
  icon_fill,
  icon_position = "right",
  form = null,
  type = "button",
  disabled = false
}) {
  return (
    <motion.button
      {...framer_configs.button_scale_animation}
      form={form}
      type={type}
      style={{ ...styles }}
      onClick={onClick}
      className={`${classes} Btn Btn-text Btn-text-icon-${icon_position}-medium`}
      disabled={disabled}
    >
      {children}
      {is_viewport_mobile ? (
        <IconSmall style={{ cursor: "pointer" }} icon={icon} stroke_color={icon_stroke} fill={icon_fill} />
      ) : (
        <IconMedium style={{ cursor: "pointer" }} icon={icon} stroke_color={icon_stroke} fill={icon_fill} />
      )}
    </motion.button>
  );
}
