import { useEffect, useRef, useState } from "react";
import { useBottomSheet } from "../bottom_sheets/BottomSheet";
import { RadioInput } from "./RadioInput";
import { IconLarge } from "../Icons";
import { svg_icons } from "../../../services/variant_constants";
import { motion } from "framer-motion";

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 300, damping: 24 },
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
};

function Dropdown({
  menu_title,
  default_value = null,
  disabled = false,
  options = [],
  register = () => {},
  onOptionSelect = () => {},
  name,
  styles = { field: {}, list_wrapper: {}, list: {}, list_item: {} },
  classes = { field: "", list_wrapper: "", list: "", list_item: "" },
  children,
}) {
  const menu_ref = useRef(null);
  const optionSheet = useBottomSheet();
  const [_selectedOption, _setSelectedOption] = useState(default_value);

  const handleDropDownFieldClick = () => {
    if (disabled) return 0;
    if (optionSheet._bottomSheetVisible) optionSheet.hideSheet();
    else optionSheet.viewSheet();
  };

  useEffect(() => {
    _setSelectedOption(default_value);
  }, [default_value]);

  const DropdownMenu = () => {
    return (
      optionSheet._bottomSheetVisible && (
        <motion.div
          style={styles.list_wrapper}
          className={`Dropdown-menu-list-wrapper ${classes.list_wrapper}`}
        >
          <motion.ul
            variants={{
              open: {
                clipPath: "inset(0% 0% 0% 0% round 10px)",
                transition: {
                  type: "spring",
                  bounce: 0,
                  duration: 0.7,
                  delayChildren: 0.3,
                  staggerChildren: 0.05,
                },
              },
              closed: {
                clipPath: "inset(10% 50% 90% 50% round 10px)",
                transition: {
                  type: "spring",
                  bounce: 0,
                  duration: 0.3,
                },
              },
            }}
            style={{
              top: /* menu_ref.current.offsetTop + */ menu_ref.current
                .offsetHeight,
              width: menu_ref.current.offsetWidth - 20 + "px",
              ...styles.list,
            }}
            className={`Dropdown-menu-list ${classes.list}`}
          >
            {options.map((option) => {
              return (
                <motion.li
                  variants={itemVariants}
                  styles={styles.list_item}
                  className={`Dropdown-menu-list-item ${classes.list_item}`}
                  key={option.value}
                >
                  <RadioInput
                    type="dropdown"
                    name={name}
                    value={option.value}
                    register={register}
                    selected_option={_selectedOption}
                    onClick={() => {
                      _setSelectedOption(option);
                      onOptionSelect(option.value);
                      optionSheet.hideSheet();
                    }}
                  >
                    {option.content}
                  </RadioInput>
                </motion.li>
              );
            })}
          </motion.ul>
        </motion.div>
      )
    );
  };

  return (
    <motion.div
      initial={"closed"}
      animate={optionSheet._bottomSheetVisible ? "open" : "closed"}
      className="Dropdown-container"
    >
      {optionSheet._bottomSheetVisible && <DropdownMenu />}
      <motion.div
        style={{ ...styles.field, cursor: disabled && "unset" }}
        ref={menu_ref}
        onClick={handleDropDownFieldClick}
        className={`${classes.field} Dropdown-field`}
      >
        {_selectedOption?.content ?? menu_title}
        <IconLarge
          style={
            optionSheet._bottomSheetVisible
              ? { transform: "rotate(180deg)" }
              : {}
          }
          icon={svg_icons.chevron_down_icon}
          stroke_color="#1f1f1f"
        />
      </motion.div>
      {children}
    </motion.div>
  );
}

export default Dropdown;
