import { useState } from "react";
import { uploadImageToFirebase } from "../services/firebase";

export const useImageStorage = ({
  config: { folder = "", taskAfterUpload = () => {} },
}) => {
  // const [_image, _setImage] = useState(null);
  const [_isUploading, _setIsUploading] = useState(false);
  const [_downloadURL, _setDownloadURL] = useState(null);
  const [_error, _setError] = useState(null);

  /*   const getImage = (image) => {
    _setImage(image);
  }; */

  const startUpload = async (image) => {
    _setIsUploading(true);
    try {
      const downloadURL = await uploadImageToFirebase(image, folder);
      if (downloadURL) {
        _setIsUploading(false);
        _setDownloadURL(downloadURL);
        taskAfterUpload(downloadURL);
        //Task after upload for logo Ref component
        /* Ref.current = logo_url;
        _setLogo(logo.url); */
      }
    } catch (err) {
      console.log(err.message);
      _setError(err.message);
    }
  };

  return {
    //getImage,
    _downloadURL,
    _isUploading,
    _error,
    startUpload,
  };
};
