import "quill/dist/quill.snow.css";
import { useEffect } from "react";

function InputFieldArea({
  quill,
  quillRef,
  initial_value,
  error,
  setValue,
  name,
  disabled,
  setError = () => {},
  resetError = () => {},
}) {
  useEffect(() => {
    if (disabled) quill?.enable(false);
    else quill?.enable();
  }, [quill, disabled]);

  useEffect(() => {
    const delta = quill?.clipboard.convert(initial_value);
    quill?.setContents(delta, "silent");
  }, [initial_value, quill]);

  useEffect(() => {
    if (quill) {
      quill.on("text-change", (e) => {
        setValue(name, quill.root.innerHTML);
        if (quill.getText().length > 20) resetError(name);
      });
    }
  }, [quill, name, setValue, resetError]);

  return (
    <div
      data-disabled={disabled}
      className={`Quill-container ${error ? "error" : ""}`}
    >
      <div id="QuillEditor" ref={quillRef} />
    </div>
  );
}

export default InputFieldArea;
