function InputError({ classes = "", styles = {}, children }) {
  return (
    <>
      {children && (
        <p className={`Error-text ${classes}`} style={styles}>
          {children}
        </p>
      )}
    </>
  );
}

export default InputError;
