import { makeNewStatuses, makeNewTimestamps } from "./common";

export const makeNewEmailAuth = ({ id, uid }) => {
  return { email: { id, uid } };
};

export const makeNewMobileAuth = ({ id, uid }) => {
  return { mobile: { id, uid } };
};

export const makeNewUserProfile = ({
  first_name = null,
  last_name = null,
  photo = null,
  email = null,
  country_code = null,
  mobile = null,
  provider = "email",
  is_email_verified = true,
  is_mobile_verified = false,
}) => {
  return {
    first_name,
    last_name,
    photo,
    email,
    country_code,
    mobile,
    provider,
    is_email_verified,
    is_mobile_verified,
  };
};

export const makeNewRoleTypes = ({
  brand = true,
  influencer = false,
  affiliate = false,
  end_user = false,
}) => {
  return { brand, influencer, affiliate, end_user };
};

export const makeNewUser = ({
  timestamps = makeNewTimestamps({}),
  status = makeNewStatuses({}),
  id = null,
  default_role = "brand",
  auth,
  type = makeNewRoleTypes({}),
  profile = makeNewUserProfile({}),
}) => {
  return { id, default_role, auth, profile, type, status, timestamps };
};

export const brand_profile_schema = {
  brand_name: "brand_name",
  brand_poc: {
    root: "brand_poc",
    name: "brand_poc.name",

    contact: {
      root: "contact",
      email: "brand_poc.contact.email",
      mobile: "brand_poc.contact.mobile",
    },
  },
  store_types: {
    root: "store_types",
    shopify: "store_types.shopify",
    magento: "store_types.magento",
    dukaan: "store_types.dukaan",
    wordpress: "store_types.wordpress",
    custom: "store_types.custom",
    others: "store_types.others",
  },
  running_other_affiliate_program: "running_other_affiliate_program",
  is_profile_complete: "is_profile_complete",
};

export const makenewBrandProfile = ({
  brand_name = null,
  brand_poc = { name: null, contact: { email: null, mobile: null } },
  provider = "email",
  is_email_verified = true,
  is_mobile_verified = false,
  running_other_affiliate_program = false,
  is_profile_complete = false,
  store_types = {
    shopify: false,
    wordpress: false,
    dukaan: false,
    custom: false,
    magento: false,
    others: false,
  },
}) => {
  return {
    brand_name,
    brand_poc,
    provider,
    is_email_verified,
    is_mobile_verified,
    store_types,
    running_other_affiliate_program,
    is_profile_complete,
  };
};

export const makeNewBrandKYC = ({ completed = null, kyc_documents = {} }) => {
  return { completed, kyc_documents };
};

export const makeNewAgreement = ({
  commercial_declaration_acknowledged = false,
  signed = false,
  signatory = null,
  agreement_id = null,
}) => {
  return {
    commercial_declaration_acknowledged,
    signed,
    agreement_id,
    signatory,
  };
};

export const makeNewBrand = ({
  auth,
  id,
  kyc = makeNewBrandKYC({}),
  profile = makenewBrandProfile({}),
  timestamps = makeNewTimestamps({}),
  status = makeNewStatuses({}),
  agreement = makeNewAgreement({}),
  affiliate = {
    store_created: null,
    product_created: null,
    api_integrated: null,
  },
}) => {
  return {
    kyc,
    auth,
    id,
    profile,
    timestamps,
    status,
    affiliate,
    agreement,
  };
};
