import "../../styles/Login.css";

import { openInNewTab } from "../../services/common";
import logo_full_white from "../../assets/branding/logo_full_white.svg";
import ContinueWithGoogle from "./ContinueWithGoogle";
import { useDispatch } from "react-redux";
import { login } from "../../store/brandSlice";
import { createBrand, createUser } from "../../services/firebase/fbAuth";
import { makeNewBrand } from "../../schema/users_schema";
import { useNavigate } from "react-router";
import { root_routes, welcome_routes } from "../../utilty";
import { useWithLoader } from "../../components/Wrappers/LoaderWrapper";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loader = useWithLoader(false);

  const authSuccessful = async ({
    brand_exists = false,
    user_exists = false,
    brand = null,
    user = null,
  }) => {
    console.log({ brand_exists, user_exists, brand, user });
    try {
      if (brand_exists) {
        loader.endLoading();
        if (!brand.profile.is_profile_complete)
          navigate(welcome_routes.onboarding.link);
        dispatch(login(brand));
        navigate(root_routes.dashboard);
        return 0;
      } else if (user_exists) {
        const created_brand = await createBrand({
          brand: { ...brand },
        });
        navigate(welcome_routes.onboarding.link);
        dispatch(login(created_brand));
        return 0;
      } else {
        const created_user = await createUser({ user: user });
        const brand = makeNewBrand({
          auth: created_user.auth,
          id: created_user.id,
        });
        const created_brand = await createBrand({ brand });
        navigate(welcome_routes.onboarding.link);
        dispatch(login(created_brand));
        return 0;
      }
    } catch (err) {
      loader.endLoading();
      loader.setError("Oops..! We couldn't log you in!");
    }
  };

  return (
    <div className="Ob-page">
      <div className="Ob-logo">
        <img src={logo_full_white} alt="Welcome to SocialPi" />
      </div>
      <div className="Ob-login-container">
        <h4 className="Ob-login-heading">Login or Sign Up</h4>

        <ContinueWithGoogle
          _withLoader={{ ...loader }}
          authSuccesful={authSuccessful}
        />
        {/* <ContinueWithPhone authSuccessful={authSuccessful} /> */}
        <p className="Ob-footer">
          By continuing, you agree to our{" "}
          <span
            onClick={() => openInNewTab("https://socialpi.ai/terms_of_use/")}
            className="fw-600 cursor-pointer"
          >
            Terms of Use
          </span>
          . <br />
          Read our{" "}
          <span
            onClick={() => openInNewTab("https://socialpi.ai/privacy_policy/")}
            className="fw-600 cursor-pointer"
          >
            Privacy Policy
          </span>
          .
        </p>
      </div>
    </div>
  );
}

export default Login;
