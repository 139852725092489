import InputFieldText from "../../components/design_system/forms/InputFieldText";
import InputLabelText from "../../components/design_system/forms/InputLabels";

function DesignSystemInputs() {
  return (
    <div>
      <InputLabelText>Title</InputLabelText>
      <InputFieldText type="text" placeholder="Text here" />
    </div>
  );
}

export default DesignSystemInputs;
