import { H1 } from "../../../components/design_system/text/Heading";
import { SH2 } from "../../../components/design_system/text/SubHeading";
import { TextIconButtonMedium } from "../../../components/design_system/Buttons";
import GlobalCommissionSettings from "../../../components/affiliate/store/GlobalCommissionSettings";
import { svg_icons } from "../../../services/variant_constants";
import { IconLarge } from "../../../components/design_system/Icons";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";
import { framer_configs } from "../../../utilty";
function GlobalAffiliateSettings() {
  const navigate = useNavigate();
  return (
    <motion.div {...framer_configs.animation.bounce_up} className="Global-affiliate-settings-page">
      <div className="Global-affiliate-settings-page-header">
        <IconLarge
          stroke_color="none"
          fill="#1f1f1f"
          icon={svg_icons.left_arrow_icon}
          onClick={() => navigate(-1)}
        />
        <H1>Global Affiliate Settings</H1>

        <TextIconButtonMedium
          icon={svg_icons.save_icon}
          icon_stroke={"#fff"}
          classes="Btn-full Btn-accent"
          form={"CommissionSettingForm"}
          styles={{ gridColumn: "3/span 1" }}
          type="submit"
        >
          Save
        </TextIconButtonMedium>
      </div>

      <SH2 classes="fc-pb-80 mt-20 mb-20">
        <span className="fc-pb fw-600">Note: </span> You’ll be able to change
        the individual product affiliate settings from the product listings.
      </SH2>
      <GlobalCommissionSettings />
      <TextIconButtonMedium
        icon={svg_icons.save_icon}
        icon_stroke={"#fff"}
        classes="Btn-full Btn-accent mt-32 margin-auto-h"
        form={"CommissionSettingForm"}
        type="submit"
      >
        Save
      </TextIconButtonMedium>
    </motion.div>
  );
}

export default GlobalAffiliateSettings;
