import {
  affiliate_entry_types,
  affiliate_models,
  commission_disbursement_periodicity,
  commission_types,
} from "../meta";
import { makeNewStatuses, makeNewTimestamps } from "./common";

export const makeNewDisbursementSettings = ({
  time_unit = commission_disbursement_periodicity.monthly,
  time = 7,
}) => {
  return {
    time_unit: time_unit,
    time: time,
  };
};

export const makeNewGlobalCommissionSettings = ({
  is_store_wide = true,
  affiliate_entry_type = affiliate_entry_types.open_for_all,
  affiliate_model = affiliate_models.cart_value,
  commission_amount = 15,
  commission_currency = "INR",
  commission_type = commission_types.fixed_percent,
  commission_disbursement_time_unit = commission_disbursement_periodicity.monthly,
  commission_disbursement_time = 17,
  cookie_duration = 28,
  maximum_return_period = 0,
  terms_and_conditions = "",
  timestamps = {
    updated_on: null,
    last_rate_revised_on: null,
    last_tnc_revised_on: null,
  },
}) => {
  return {
    is_store_wide,
    affiliate_entry_type: affiliate_entry_type,
    affiliate_model: affiliate_model,
    commission_amount: commission_amount,
    commission_currency: commission_currency,
    commission_type: commission_type,
    maximum_return_period,
    cookie_duration: cookie_duration,
    disbursement: {
      time_unit: commission_disbursement_time_unit,
      time: commission_disbursement_time,
    },
    terms_and_conditions: terms_and_conditions,
    timestamps,
  };
};

export const globalCommissionSettingsSchema = {
  is_store_wide: "is_store_wide",
  affiliate_entry_type: "affiliate_entry_type",
  affiliate_model: "affiliate_model",
  commission_amount: "commission_amount",
  commission_currency: "commission_currency",
  commission_type: "commission_type",
  cookie_duration: "cookie_duration",
  maximum_return_period: "maximum_return_period",
  disbursement: {
    root: "disbursement",
    time_unit: "disbursement.time_unit",
    time: "disbursement.time",
  },
  terms_and_conditions: "terms_and_conditions",
  timestamps: {
    root: "timestamps",
    updated_on: "timestamps.updated_on",
    next_payment_on: "timestamps.next_payment_on",
  },
};

export const makeNewStoreDetails = ({
  name = "",
  banner = "",
  logo = "",
  description = "",
  audience_restriction = null,
  legal_declaration = false,
}) => {
  return {
    name,
    banner,
    logo,
    description,
    audience_restriction,
    legal_declaration,
  };
};

export const makeNewAPIIntegrationDetails = ({
  api_key = null,
  api_key_generated_on = null,
  is_active = false,
  last_callback_sent_on = null,
  last_monitored_on = null,
}) => {
  return {
    api_key,
    api_key_generated_on,
    is_active,
    last_callback_sent_on,
    last_monitored_on,
  };
};

export const makeNewStoreCommissionRanges = ({
  amount = { lower: null, upper: null },
  percent = { lower: null, upper: null },
}) => {
  return { amount, percent };
};

export const makeNewStoreCounters = ({
  total_affiliates = 0,
  total_commissions_paid = 0,
  total_disputes_raised = 0,
  total_products = 0,
  total_sales_amount = 0,
  commission_amount_pending = 0,
}) => {
  return {
    commission_amount_pending,
    total_affiliates,
    total_commissions_paid,
    total_disputes_raised,
    total_products,
    total_sales_amount,
  };
};

export const makeNewStore = ({
  id = "test_store",
  link,
  brand = "test_brand",
  is_affiliate_active = true,
  api_integrated = false,
  global_settings = makeNewGlobalCommissionSettings({}),
  auto_payment_enabled = false,
  store_details = makeNewStoreDetails({}),
  timestamps = makeNewTimestamps({}),
  status = makeNewStatuses({}),
  api_integration = makeNewAPIIntegrationDetails({}),
  counters = makeNewStoreCounters({}),
  settings_history = {
    rate_revisions: [],
    tnc_revisions: [],
    general_setting_history: [],
  },
  commission_ranges = makeNewStoreCommissionRanges({}),
}) => {
  return {
    id,
    link,
    store_details,
    is_affiliate_active,
    brand,
    api_integrated,
    global_settings,
    auto_payment_enabled,
    timestamps: { ...timestamps, last_payment_on: null, next_payment_on: null },
    status,
    api_integration,
    counters,
    settings_history,
    commission_ranges,
  };
};

export const makeNewStoreProductDetails = ({
  title = "",
  description = "",
  media = [],
  tags = [],
}) => {
  return {
    title: title,
    description: description,
    media: media,
    tags: tags,
  };
};

export const makeNewStoreProductCounters = ({
  total_affiliates = 0,
  total_returns = 0,
  total_sales = 0,
  total_revenue = 0,
}) => {
  return { total_affiliates, total_returns, total_sales, total_revenue };
};

export const makeNewStoreProduct = ({
  id = null,
  brand,
  store,
  link,
  product_details = makeNewStoreProductDetails({}),
  is_affiliate_active = true,
  is_under_global = true,
  custom_settings = null,
  timestamps = makeNewTimestamps({}),
  status = makeNewStatuses({}),
  counters = makeNewStoreProductCounters({}),
  settings_history = {},
}) => {
  return {
    id,
    brand,
    store,
    link,
    product_details,
    is_affiliate_active,
    is_under_global,
    custom_settings,
    timestamps,
    status,
    counters,
    settings_history,
  };
};

export const store_product_details_schema = {
  title: "title",
  description: "description",
  media: "media",
  tags: "tags",
};

export const store_product_schema = {
  id: "id",
  brand: "brand",
  store: "store",
  link: "link",
  product_details: {
    root: "product_details",
    title: "product_details.title",
    description: "product_details.description",
    media: "product_details.media",
    tags: "product_details.tags",
  },
  is_affiliate_active: "is_affiliate_active",
  is_under_global: "is_under_global",
  custom_settings: {
    root: "custom_settings",
    affiliate_entry_type: "custom_settings.affiliate_entry_type",
    affiliate_model: "custom_settings.affiliate_model",
    commission_amount: "custom_settings.commission_amount",
    commission_currency: "custom_settings.commission_currency",
    commission_type: "custom_settings.commission_type",
    cookie_duration: "custom_settings.cookie_duration",
    disbursement: {
      root: "disbursement",
      time_unit: "custom_settings.disbursement.time_unit",
      time: "custom_settings.disbursement.time",
    },
    terms_and_conditions: "custom_settings.terms_and_conditions",
    timestamps: { root: "timestamps", updated_on: "timestamps.updated_on" },
  },
};
