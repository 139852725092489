export function H1({ classes = "", styles = {}, children }) {
  return (
    children && (
      <h1 style={{ ...styles }} className={`${classes} H1`}>
        {children}
      </h1>
    )
  );
}

export function H2({ classes = "", styles = {}, children }) {
  return (
    children && (
      <h2 style={{ ...styles }} className={`${classes} H2`}>
        {children}
      </h2>
    )
  );
}

export function H3({ classes = "", styles = {}, children }) {
  return (
    children && (
      <h3 style={{ ...styles }} className={`${classes} H3`}>
        {children}
      </h3>
    )
  );
}

export function H4({ classes = "", styles = {}, children }) {
  return (
    children && (
      <h4 style={{ ...styles }} className={`${classes} H4`}>
        {children}
      </h4>
    )
  );
}
