export const root_api_routes = {
  api_base:
    process.env.NODE_ENV === "development"
      ? "http://127.0.0.1:5001/socialpi-app/us-central1/brand_app"
      : "https://us-central1-socialpi-app.cloudfunctions.net/brand_app",
  meta_scraping: "/api/meta_scraping",
};

export const meta_scraping_routes = {
  get_brand_store_meta: root_api_routes.meta_scraping + "/get_brand_store_meta",
  get_brand_product_meta:
    root_api_routes.meta_scraping + "/get_brand_product_meta",
  get_brand_store_products_xml:
    root_api_routes.meta_scraping + "/get_brand_store_products_xml",
};
