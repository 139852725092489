import { useDispatch, useSelector } from "react-redux";
import { SH2 } from "../../design_system/text/SubHeading";
import ProductListRow from "./ProductListRow";
import { motion } from "framer-motion";
import { framer_configs, product_routes } from "../../../utilty";
import { H3 } from "../../design_system/text/Heading";
import { TextIconButtonMedium } from "../../design_system/Buttons";
import { useNavigate } from "react-router";
import { svg_icons } from "../../../services/variant_constants";
import { getSiteMap } from "../../../services/shopify/productFetcher";
import { useContext } from "react";
import { ViewportContext } from "../../Providers/ViewportProvider";
import UploadLoader from "../../common/UploadLoader";
import BottomSheet from "../../design_system/bottom_sheets/BottomSheet";

function ProductListing() {
  const products = useSelector((state) => state.store_products);
  const { is_mobile } = useContext(ViewportContext);


  return (
    <motion.ul
      {...framer_configs.parent_framer_animations.stagger}
      className="Product-listing-table"
    >
      {/*  <UploadLoader /> */}
      <li className="Product-listing-row">
        <SH2>Products</SH2>
        {!is_mobile && <SH2>Commission</SH2>}
        {!is_mobile && <SH2>Rate</SH2>}
        {!is_mobile && <SH2>Visits</SH2>}
        <SH2>Sales</SH2>
        {!is_mobile && <SH2>Revenue</SH2>}
        {!is_mobile && <SH2>Edit</SH2>}
      </li>
      {Object.values(products).map((product) => {
        return <ProductListRow key={product.id} product={product} />;
      })}
    </motion.ul>
  );
}

export default ProductListing;
