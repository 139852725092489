import InputLabelText from "../../design_system/forms/InputLabels";
import ProductTagsInput from "./ProductTagsInput";
import ProductMediaInput from "./ProductsMediaInput";

function ProductDetailsForm({
  ref_tags,
  ref_media,
  register,
  names: { title, description },
}) {
  return (
    <section className="Affiliate-product-form Affiliate-settings-form">
      <div>
        <InputLabelText>Product Title</InputLabelText>
        <input
          className="Input-text"
          placeholder="Product Title"
          {...register(title)}
        />
      </div>

      <div>
        <InputLabelText>Description</InputLabelText>
        <textarea
          style={{ height: "200px" }}
          className="Input-text"
          {...register(description)}
        />
      </div>
      <ProductTagsInput Ref_tags={ref_tags} />
      <ProductMediaInput Ref_media={ref_media} />
    </section>
  );
}

export default ProductDetailsForm;
