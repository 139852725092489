import { useSelector } from "react-redux";

function AffiliateListRow({ affiliate_id }) {
  const affiliate = useSelector((state) => state.affiliates[affiliate_id]);
  return (
    <li className="Affiliate-list-row">
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <img
          style={{
            width: "50px",
            aspectRatio: "1/1",
            objectFit: "cover",
            objectPosition: "top",
            borderRadius: "100%",
          }}
          src={affiliate.user_profile.photo}
          alt="affiliate"
        />
        <p>
          {affiliate.user_profile.first_name} {affiliate.user_profile.last_name}
        </p>
      </div>
      <p> {affiliate.stats.active.clicks ?? "0"}</p>
      <p>{affiliate.stats.active.sales} </p>
      <p>Rs. {affiliate.stats.active.sales_amount ?? "0"}</p>
    </li>
  );
}

export default AffiliateListRow;
