import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { fb_db } from "../services/firebase";

export const useGetAgreement = ({ brand }) => {
  const [loading, setLoading] = useState(true);
  const [agreement, setAgreement] = useState(null);
  useEffect(() => {
    const agreement_query = query(
      collection(fb_db, "affiliate_agreements"),
      where("brand", "==", brand),
      where("status", "==", "signed")
    );
    getDocs(agreement_query).then((agreement_doc) => {
      if (agreement_doc.docs.length > 0)
        setAgreement(
          "data:application/pdf;base64," +
            agreement_doc.docs[0].data().decoded_doc
        );
      setLoading(false);
    });
  }, [brand]);

  return { loading, agreement };
};
