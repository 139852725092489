import { useEffect, useState } from "react";
import InputLabelText from "../../design_system/forms/InputLabels";
import { IconMedium } from "../../design_system/Icons";
import { svg_icons } from "../../../services/variant_constants";

function ProductTagsInput({ Ref_tags }) {
  const [_tags, _setTags] = useState([...Ref_tags.current]);

  useEffect(() => {
    Ref_tags.current = [..._tags];
  }, [_tags, Ref_tags]);

  return (
    <div>
      <InputLabelText>Tags</InputLabelText>
      <input
        onChange={(e) => {
          if (e.target.value.endsWith(",")) {
            if (_tags.includes(e.target.value.replaceAll(",", ""))) {
              e.target.value = "";
              alert("Oops this tag already added");
              return 0;
            }
            _setTags([..._tags, e.target.value.replace(",", "")]);
            e.target.value = "";
          }
        }}
        className="Input-text"
        disabled={_tags.length === 10}
        placeholder={
          _tags.length === 10 ? "Maximum tags added!" : "Tags of your product"
        }
      />
      {_tags.length > 0 && (
        <ul className="Tag-list mt-12">
          {_tags.map((tag) => {
            return (
              <li className="Tag-list-item" key={tag}>
                {tag}
                <IconMedium
                  icon={svg_icons.cross_icon}
                  stroke_color={"none"}
                  fill={"#1f1f1f"}
                  onClick={() => {
                    _setTags(_tags.filter((state_tag) => tag !== state_tag));
                  }}
                />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

export default ProductTagsInput;
