import { svg_icons } from "../../../services/variant_constants";
import { framer_configs } from "../../../utilty";
import Chip from "../../design_system/Chips";
import { IconSmall } from "../../design_system/Icons";
import { H2, H3 } from "../../design_system/text/Heading";
import { motion } from "framer-motion";

function StatCard({
  styles = { container: {}, title: {}, stat: {} },
  classes = { container: "", title: "", stat: "" },
  title = "Statistic",
  stat = "Rs. 2000",
  growth = -50,
}) {
  return (
    <motion.div
      variants={{ ...framer_configs.variants.bounce_scale }}
      style={styles.container}
      className={`Stat-card ${classes.container}`}
    >
      <H3 styles={styles.title} classes={`${classes.title} fw-500`}>
        {title}
      </H3>
      <H2 classes="fw-500">{stat}</H2>
      {/*   <Chip classes={growth > 0 ? "Trend Trend-green" : "Trend Trend-red"}>
        {Math.abs(growth)}%
        <IconSmall
          stroke_color={
            growth > 0 ? "var(--color-green-solid)" : "var(--color-red-solid)"
          }
          icon={growth > 0 ? svg_icons.trend_icon : svg_icons.trend_down_icon}
        />
      </Chip> */}
    </motion.div>
  );
}

export default StatCard;
