import { svg_icons } from "../../../services/variant_constants";
import BottomSheet, { useBottomSheet } from "../bottom_sheets/BottomSheet";
import { OptionWithIcon } from "./SelectMenuOptions";

const default_option_props = {
  title: "Option",
  subtitle: "This will be detail",
  icon: svg_icons.plus_icon,
};

function SelectMenu({
  classes = { list: "", list_option: "" },
  styles = { list: {}, list_option: {} },
  options = [
    { id: "", Component: <OptionWithIcon {...default_option_props} /> },
  ],
  optionSelectHandler,
  title,
  selected_option = null,
}) {
  const selectionMenu = useBottomSheet();
  return (
    <div onClick={selectionMenu.viewSheet}>
      <p>{selected_option !== null ? selected_option : title}</p>

      <BottomSheet title={"Option Menu"} {...selectionMenu}>
        <ul style={styles.list} className={classes.list}>
          {options.map((option, index) => {
            return <div key={index}>{option.Component}</div>;
          })}
        </ul>
      </BottomSheet>
    </div>
  );
}

export default SelectMenu;
