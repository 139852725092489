import { svg_icons } from "../../../services/variant_constants";
import { ReactComponent as IconTickRound } from "./icon_tick_round.svg";
import { ReactComponent as IconCross } from "./cross_icon.svg";
import { ReactComponent as IconPlus } from "./plus_icon.svg";
import { ReactComponent as DashboardMenuIcon } from "./dashboard_menu_icon.svg";
import { ReactComponent as StoreMenuIcon } from "./store_menu_icon.svg";
import { ReactComponent as ProductsMenuIcon } from "./products_menu_icon.svg";
import { ReactComponent as AffiliatesMenuIcon } from "./affiliates_menu_icon.svg";
import { ReactComponent as WalletMenuIcon } from "./wallet_menu_icon.svg";
import { ReactComponent as SettingsMenuIcon } from "./settings_menu_icon.svg";
import { ReactComponent as UploadIcon } from "./upload_icon.svg";
import { ReactComponent as RightArrowIcon } from "./right_arrow_icon.svg";
import { ReactComponent as LeftArrowIcon } from "./left_arrow_icon.svg";
import { ReactComponent as InfoIcon } from "./info_icon.svg";
import { ReactComponent as SaveIcon } from "./save_icon.svg";
import { ReactComponent as TrashIcon } from "./trash_icon.svg";
import { ReactComponent as ChevrondownIcon } from "./chevron_down_icon.svg";
import { ReactComponent as SettingsIcon } from "./settings_icon.svg";
import { ReactComponent as PencilIcon } from "./pencil_icon.svg";
import { ReactComponent as TrendIcon } from "./trend_icon.svg";
import { ReactComponent as TrendDownIcon } from "./trend_icon_down.svg";
import { ReactComponent as LogoutIcon } from "./logout_icon.svg";
import { ReactComponent as CartIcon } from "./cart_icon.svg";
import { ReactComponent as ConnectionIcon } from "./connection_icon.svg";
import { ReactComponent as TwinkleBlue } from "../icons/twinkle_blue.svg";
import { ReactComponent as AlertIcon } from "../icons/alert_icon.svg";
import { ReactComponent as LinkIcon } from "../icons/link_icon.svg";
import { ReactComponent as CopyIcon } from "../icons/copy_icon.svg";
import { ReactComponent as PictureIcon } from "../icons/picture_icon.svg";
import { ReactComponent as ReelIcon } from "../icons/reel_icon.svg";

export function SVGIcon({
  onClick,
  icon,
  size = "18",
  fill = "none",
  stroke_color = "#1f1f1f",
  stroke_width = 1.6,
  style,
}) {
  const props = {
    onClick: onClick,
    fill: fill,
    stroke: stroke_color,
    strokeWidth: stroke_width,
    height: size,
    width: size,
    style: style,
  };
  switch (icon) {
    case svg_icons.round_tick:
      return <IconTickRound {...props} />;
    case svg_icons.cross_icon:
      return <IconCross {...props} />;
    case svg_icons.plus_icon:
      return <IconPlus {...props} />;
    case svg_icons.dashboard_menu_icon:
      return <DashboardMenuIcon {...props} />;
    case svg_icons.store_menu_icon:
      return <StoreMenuIcon {...props} />;
    case svg_icons.products_menu_icon:
      return <ProductsMenuIcon {...props} />;
    case svg_icons.affiliates_menu_icon:
      return <AffiliatesMenuIcon {...props} />;
    case svg_icons.wallet_menu_icon:
      return <WalletMenuIcon {...props} />;
    case svg_icons.settings_menu_icon:
      return <SettingsMenuIcon {...props} />;
    case svg_icons.upload_icon:
      return <UploadIcon {...props} />;
    case svg_icons.right_arrow_icon:
      return <RightArrowIcon {...props} />;
    case svg_icons.left_arrow_icon:
      return <LeftArrowIcon {...props} />;
    case svg_icons.info_icon:
      return <InfoIcon {...props} />;
    case svg_icons.save_icon:
      return <SaveIcon {...props} />;
    case svg_icons.trash_icon:
      return <TrashIcon {...props} />;
    case svg_icons.chevron_down_icon:
      return <ChevrondownIcon {...props} />;
    case svg_icons.settings_icon:
      return <SettingsIcon {...props} />;
    case svg_icons.pencil_icon:
      return <PencilIcon {...props} />;
    case svg_icons.trend_icon:
      return <TrendIcon {...props} />;
    case svg_icons.trend_down_icon:
      return <TrendDownIcon {...props} />;
    case svg_icons.logout_icon:
      return <LogoutIcon {...props} />;
    case svg_icons.connection_icon:
      return <ConnectionIcon {...props} />;
    case svg_icons.cart_icon:
      return <CartIcon {...props} />;
    case svg_icons.twinkle_blue:
      return <TwinkleBlue {...props} />;
    case svg_icons.alert_icon:
      return <AlertIcon {...props} />;
    case svg_icons.link_icon:
      return <LinkIcon {...props} />;
    case svg_icons.copy_icon:
      return <CopyIcon {...props} />;
    case svg_icons.picture_icon:
      return <PictureIcon {...props} />;
    case svg_icons.reel_icon:
      return <ReelIcon {...props} />;
    default:
      break;
  }
}
