import { initializeApp, getApps, getApp } from "firebase/app";
import { getAuth } from "firebase/auth";

import { getFirestore } from "firebase/firestore";

import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = !getApps.length ? initializeApp(firebaseConfig) : getApp();
export const fb_auth = getAuth(app);
export const fb_db = getFirestore(app);
export const fb_storage = getStorage(app);

export const uploadImageToFirebase = async (
  file,
  folder,
  setProgress = () => {}
) => {
  const metadata = {
    contentType: "image/jpeg",
  };
  const imageRef = ref(fb_storage, folder);
  const uploadTask = uploadBytesResumable(imageRef, file, metadata);
  return new Promise((resolve) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress.toFixed(0));

        switch (snapshot.state) {
          case "paused":
            break;
          case "running":
            break;
          default:
            break;
        }
      },
      (error) => {
        switch (error.code) {
          case "storage/unauthorized":
            break;
          case "storage/canceled":
            break;
          case "storage/unknown":
            break;
          default:
            break;
        }
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          return resolve(downloadURL);
        });
      }
    );
  });
};

export const storage_routes = {
  store_details: "affiliate_store_uploads/store_details/",
};

export const getStoreLogoStorage = ({ brand, store = "/initial" }) => {
  return storage_routes.store_details + brand + store + "/logo";
};

export const getStoreBannerStorage = ({ brand, store = "/initial" }) => {
  return storage_routes.store_details + brand + store + "/banner";
};
