export function P1({ classes, styles, children }) {
  return (
    <p style={{ ...styles }} className={`${classes} BT1`}>
      {children}
    </p>
  );
}

export function P2({ classes, styles, children }) {
  return (
    <p style={{ ...styles }} className={`${classes} BT2`}>
      {children}
    </p>
  );
}

export function P3({ classes, styles, children }) {
  return (
    <p style={{ ...styles }} className={`${classes} BT3`}>
      {children}
    </p>
  );
}
