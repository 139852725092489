import { InputHeaderTextWithIcon } from "../../../design_system/forms/InputGroupHeader";

function ReturnPeriodField({ register = () => { }, name, disabled = false }) {
  return (
    <div data-disabled={disabled} className="Affiliate-settings-input-group">
      <InputHeaderTextWithIcon classes={{ container: "mb-16" }}>
        Store Return Policy
        <br /><span className="BT2 fw-400">Affiliate Sales become qualified after return period is over</span>
      </InputHeaderTextWithIcon>
      <div style={{ display: "flex" }}>
        <input
          disabled={disabled}
          className="Input-text"
          type="number"
          min={0}
          max={60}
          step={1}
          required
          placeholder="Maximum #Days for return of items"
          {...register(name)}
        />
        <div className="Input-field-static-text">Days</div>
      </div>
    </div>
  );
}

export default ReturnPeriodField;
