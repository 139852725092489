import {
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { TextIconButtonMedium } from "../../../components/design_system/Buttons";
import { svg_icons } from "../../../services/variant_constants";
import { H1 } from "../../../components/design_system/text/Heading";
import { useDispatch, useSelector } from "react-redux";
import { useRef, useState } from "react";
import { InputHeaderTextWithIcon } from "../../../components/design_system/forms/InputGroupHeader";
import ToggleInput from "../../../components/design_system/forms/ToggleInput";
import { useForm } from "react-hook-form";
import {
  makeNewStoreProduct,
  makeNewStoreProductDetails,
  store_product_schema,
} from "../../../schema/affiliate_schema";
import ProductSettingsForm from "../../../components/affiliate/products/ProductSettingsForm";
import ProductDetailsForm from "../../../components/affiliate/products/ProductDetailsForm";
import {
  createProduct,
  updateProduct,
} from "../../../services/firebase/fbAffiliate";
import { addProducts, saveProduct } from "../../../store/productSlice";
import { useBottomSheet } from "../../../components/design_system/bottom_sheets/BottomSheet";
import DiscardSheet from "../../../components/design_system/bottom_sheets/DiscardSheet";
import { product_routes, root_routes } from "../../../utilty";
import { IconLarge } from "../../../components/design_system/Icons";
import WithLoader, {
  useWithLoader,
} from "../../../components/Wrappers/LoaderWrapper";

const product_schema = store_product_schema;

function ProductDetail() {
  const { product_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const confirmDiscardSheet = useBottomSheet();

  const product = useSelector(
    (state) => state.store_products[product_id] ?? null
  );
  const global_settings = useSelector(
    (state) => state.brand_store.global_settings
  );
  const store_id = useSelector((state) => state.brand_store.id);

  const { state } = useLocation();
  const loader_data = useLoaderData();

  console.log(state);
  console.log(loader_data);

  const loader = useWithLoader(false);
  const ref_product = useRef(product || loader_data || state);
  const ref_tags = useRef(ref_product.current?.product_details?.tags ?? []);
  const ref_media = useRef(ref_product.current?.product_details?.media ?? []);

  const [_isUnderGlobal, _setIsUnderGlobal] = useState(
    ref_product.current.is_under_global
  );

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      ...ref_product.current,
      custom_settings: ref_product.is_under_global
        ? { ...global_settings }
        : ref_product.current.custom_settings ?? global_settings,
    },
  });

  const product_settings_defaults =
    _isUnderGlobal || !product?.custom_settings
      ? {
          terms_and_conditions: global_settings.terms_and_conditions,

          commission_type: global_settings.commission_type,
          time_unit: global_settings.disbursement.time_unit,
          time: global_settings.disbursement.time,
        }
      : {
          terms_and_conditions: product.custom_settings.terms_and_conditions,

          commission_type: product.custom_settings.commission_type,
          time_unit: product.custom_settings.disbursement.time_unit,
          time: product.custom_settings.disbursement.time,
        };

  const handleGlobalToggleClick = (e) => {
    _setIsUnderGlobal(e.target.checked);
  };

  const handleProductCreation = async (p) => {
    const new_product = makeNewStoreProduct({
      ...p,
      custom_settings: p.is_under_global ? null : p.custom_settings,
    });

    try {
      const created_product = await createProduct({
        store_id,
        product: { ...new_product },
      });

      if (created_product.error) {
        alert(created_product.message);
        return 0;
      } else {
        dispatch(
          addProducts({
            [created_product.id]: {
              ...created_product,
            },
          })
        );
        navigate(product_routes.list.link);
      }
    } catch (err) {
      alert(err.message);
      return 0;
    }
  };

  const handleProductDiscard = () => {
    confirmDiscardSheet.viewSheet();
  };

  const handleProductSave = async (p) => {
    try {
      const updated_product = await updateProduct({
        product: {
          ...p,
          custom_settings: p.is_under_global ? null : p.custom_settings,
        },
      });

      console.log(updated_product);
      if (updated_product.error) {
        alert(updated_product.message);
        return 0;
      }
      dispatch(saveProduct({ ...updated_product.product }));
    } catch (err) {
      alert(err.message);
      return 0;
    }
  };

  const onSubmit = async (data) => {
    console.log(ref_tags.current);
    console.log(ref_media.current);
    console.log(data);
    loader.startLoading();
    const product_details = makeNewStoreProductDetails({
      ...data.product_details,
      media: ref_media.current,
      tags: ref_tags.current,
    });
    if (!ref_product.current.id)
      await handleProductCreation({ ...data, product_details });
    else await handleProductSave({ ...data, product_details });
    loader.endLoading();
  };

  return (
    <div className="Product-detail-page">
      <div className="Global-affiliate-settings-page-header">
        <IconLarge
          stroke_color="none"
          fill="#1f1f1f"
          icon={svg_icons.left_arrow_icon}
          onClick={() => navigate(-1)}
        />
        <H1>Product Details</H1>
        <TextIconButtonMedium
          icon={svg_icons.trash_icon}
          onClick={handleProductDiscard}
        >
          Discard
        </TextIconButtonMedium>
        <WithLoader
          classes={"display-inline-flex Btn Btn-text-medium"}
          _loading={loader._loading}
          enableSuccessPop={true}
        >
          <TextIconButtonMedium
            icon={svg_icons.save_icon}
            icon_stroke={"#fff"}
            classes="Btn-accent"
            type="submit"
            form={"FormAffiliateProduct"}
          >
            Save
          </TextIconButtonMedium>
        </WithLoader>
      </div>
      <form
        id="FormAffiliateProduct"
        onSubmit={handleSubmit(onSubmit)}
        className="Product-detail-forms-grid"
      >
        <div>
          <div className="Affiliate-settings-form mb-24">
            <InputHeaderTextWithIcon>
              Affiliate Commission
            </InputHeaderTextWithIcon>
            <ToggleInput
              onClick={handleGlobalToggleClick}
              register={register}
              name={product_schema.is_under_global}
            >
              Use Global Settings
            </ToggleInput>
          </div>

          <ProductSettingsForm
            setValue={setValue}
            disabled={_isUnderGlobal}
            register={register}
            default_values={product_settings_defaults}
          />
        </div>

        <div>
          <div className="Affiliate-settings-form mb-24">
            <InputHeaderTextWithIcon>Product Link</InputHeaderTextWithIcon>
            <input
              disabled={true}
              className="Input-text"
              {...register(product_schema.link)}
            />
          </div>

          <div className="Affiliate-settings-form mb-24">
            <InputHeaderTextWithIcon>Product Status</InputHeaderTextWithIcon>
            <ToggleInput
              register={register}
              name={product_schema.is_affiliate_active}
              classes={"mt-24"}
            >
              Active for affiliate
            </ToggleInput>
          </div>

          <ProductDetailsForm
            register={register}
            names={{
              description: product_schema.product_details.description,
              title: product_schema.product_details.title,
            }}
            ref_tags={ref_tags}
            ref_media={ref_media}
          />
        </div>
      </form>

      <div className="Product-details-form-button">
        <TextIconButtonMedium
          icon={svg_icons.trash_icon}
          onClick={handleProductDiscard}
        >
          Discard
        </TextIconButtonMedium>
        <WithLoader
          classes={"display-inline-flex Btn Btn-text-medium"}
          _loading={loader._loading}
          enableSuccessPop={true}
        >
          <TextIconButtonMedium
            icon={svg_icons.save_icon}
            icon_stroke={"#fff"}
            classes="Btn-accent"
            type="submit"
            form={"FormAffiliateProduct"}
          >
            Save
          </TextIconButtonMedium>
        </WithLoader>
      </div>
      {confirmDiscardSheet._bottomSheetVisible && (
        <DiscardSheet
          sheet_props={{ ...confirmDiscardSheet }}
          handleConfirm={() => navigate(root_routes.products)}
          handleCancel={() => confirmDiscardSheet.hideSheet()}
        />
      )}
    </div>
  );
}

export default ProductDetail;
