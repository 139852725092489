import InputError from "../../../design_system/forms/InputError";
import InputLabelText from "../../../design_system/forms/InputLabels";
import { RadioInput } from "../../../design_system/forms/RadioInput";
import { P3 } from "../../../design_system/text/BodyText";
import { disclaimer_details } from "./props/adultDisclaimerProps";

function AdultDisclaimer({ error, register, name = "is_store_adult" }) {
  return <div>
    <InputLabelText>Age Restriction</InputLabelText>
    <RadioInput register={register} name={name} value={"none"}>
      Don't restrict my store to only 18+ audience
    </RadioInput>
    <RadioInput classes={{ container: "mt-8" }} register={register} name={name} value={"18+"}>
      Restrict my store to only 18+ audience
    </RadioInput>
    <P3 styles={{ paddingLeft: "30px" }} classes="mt-4 fc-pb-60">{disclaimer_details}</P3>
    {error && <InputError>{error.message}</InputError>}
  </div>
}

export default AdultDisclaimer;