import { createSlice } from "@reduxjs/toolkit";

const initial_state = {};

const product_details_reducer = {
  initProducts: (state, action) => {
    return { ...action.payload };
  },
  clearProducts: () => {
    return {};
  },
  addProducts: (state, action) => {
    return { ...state, ...action.payload };
  },
  saveProduct: (state, action) => {
    const { id } = action.payload;
    state[id] = { ...state[id], ...action.payload };
  },
};

const productSlice = createSlice({
  name: "store_products",
  initialState: initial_state,
  reducers: {
    ...product_details_reducer,
  },
});

export const { initProducts, clearProducts, addProducts, saveProduct } =
  productSlice.actions;
export default productSlice.reducer;
