import { useNavigate } from "react-router";
import { TextIconButtonMedium } from "../../../components/design_system/Buttons";
import { product_routes, settings_routes } from "../../../utilty";
import { H1, H3 } from "../../../components/design_system/text/Heading";
import { svg_icons } from "../../../services/variant_constants";
import ProductListing from "../../../components/affiliate/products/ProductListing";
import { useDispatch, useSelector } from "react-redux";
import { getSiteMap } from "../../../services/shopify/productFetcher";
import { SH2 } from "../../../components/design_system/text/SubHeading";

function ProductListingPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleAddProductClick = () => {
    navigate(product_routes.add_product_with_link.link);
  };
  const handleGlobalSettingsClick = () => {
    navigate(settings_routes.global_affiliate_settings.link);
  };

  const products = useSelector((state) => state.store_products)

  const is_store_wide = useSelector((state) => state.brand_store.global_settings.is_store_wide ?? true)
  const brand = useSelector((state) => state.brand.id)
  const store = useSelector((state) => state.brand_store.id)
  const store_link = useSelector((state) => state.brand_store.link)
  const product_length = Object.keys(products).length;

  const handleFetchProducts = () => {
    getSiteMap({ url: store_link, dispatch, store, brand, });
  }

  if (!is_store_wide && product_length === 0)
    return <div className="Product-list-empty">
      <H3 classes="fc-pb-60 fw-500">You don't have any products yet!</H3>
      <SH2 classes="fw-600 fc-accent">Add Exclusive Products on which you want to run your affiliate program.</SH2>
      {<TextIconButtonMedium
        icon={svg_icons.link_icon}
        icon_stroke={"#1f1f1f"}
        onClick={handleAddProductClick}
        classes="Btn-secondary Btn-full mt-16 display-inline-flex"
      >
        Load my products
      </TextIconButtonMedium>}
    </div>

  if (product_length === 0)
    return <div className="Product-list-empty">
      <H3 classes="fc-pb-60 fw-500">You can now add products..!</H3>
      <SH2 classes="fw-600 fc-accent">Your affiliate program is configured! Add existing products from your store with the tap of a button</SH2>
      <TextIconButtonMedium
        icon={svg_icons.link_icon}
        icon_stroke={"#1f1f1f"}
        onClick={handleAddProductClick}
        classes="Btn-secondary Btn-full mt-16 display-inline-flex"
      >
        Load my products
      </TextIconButtonMedium>
    </div>

  return (
    <div className="Product-listing-page">
      <div className="Product-listing-page-header">
        <H1 classes="fw-500">Manage Products</H1>

        {product_length !== 0 && <TextIconButtonMedium
          icon={svg_icons.link_icon}
          icon_stroke={"var(--color-green-solid)"}
          onClick={handleAddProductClick}
          icon_position="left"
          classes="Btn-success"
        >
          Add Product via Link
        </TextIconButtonMedium>}
        <TextIconButtonMedium
          icon={svg_icons.settings_icon}
          onClick={handleGlobalSettingsClick}
        >
          Global Commission Settings
        </TextIconButtonMedium>
      </div>

      <div className="Product-listing-table-container">
        <div className="Product-listing-table-header">
          <H3 classes="fw-600">Your Products</H3>
        </div>
        <ProductListing />
      </div>
    </div>
  );
}

export default ProductListingPage;
