import "../styles/Dashboard.css";
import AppMenu from "../components/dashboard/AppMenu";
import { Outlet, useNavigation } from "react-router";

function WelcomeLayout({ children, disabled }) {
  const { state } = useNavigation();

  return (
    <div className="App-layout">
      <AppMenu disabled={disabled} />
      <div className="App-outlet">
        {state === "loading" ? <p>Loading...</p> : <Outlet />}
        {children}
      </div>
    </div>
  );
}

export default WelcomeLayout;
