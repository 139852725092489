import { commission_types } from "../../../meta";
import { store_product_schema } from "../../../schema/affiliate_schema";
import BottomSheet, {
  useBottomSheet,
} from "../../design_system/bottom_sheets/BottomSheet";
import { InputHeaderTextWithIcon } from "../../design_system/forms/InputGroupHeader";
import AffiliateEntryType from "../store/global_commission_settings/AffiliateEntryType";
import AffiliateModel from "../store/global_commission_settings/AffiliateModel";
import AffiliateTermsAndConditions from "../store/global_commission_settings/AffiliateTermsAndConditions";
import CommissionDisbursement from "../store/global_commission_settings/CommissionDisbursement";
import CommissionRateField from "../store/global_commission_settings/CommissionRateField";
//import StoreCookieDuration from "../store/global_commission_settings/StoreCookieDuration";

const {
  affiliate_model,
  affiliate_entry_type,
  commission_amount,
  commission_type,
  disbursement: { time, time_unit },
  //cookie_duration,
  terms_and_conditions,
} = store_product_schema.custom_settings;

function ProductSettingsForm({
  disabled = true,
  register,
  setValue,
  default_values = {
    terms_and_conditions: "",
    commission_type: commission_types.fixed_amount,
    time_unit: "monthly",
    time: "7",
  },
}) {
  const infoSheet = useBottomSheet();
  return (
    <div className="Affiliate-settings-form">
      {infoSheet._bottomSheetVisible && (
        <BottomSheet title={"Product commission"} {...infoSheet}>
          Your products will use your store's global commission settings by
          default. To customize settings for individual products, turn off the
          global settings toggle button and edit the commission settings as per
          your convinience
        </BottomSheet>
      )}
      <InputHeaderTextWithIcon
        icon_props={{
          onClick: () => {
            infoSheet.viewSheet();
          },
        }}
        styles={{
          container: { marginBottom: 0 },
          text: { fontSize: "var(--font-size-SH1)" },
        }}
        classes={{ text: "fw-600" }}
      >
        Product Commision Settings
      </InputHeaderTextWithIcon>
      <AffiliateEntryType
        disabled={disabled}
        register={register}
        name={affiliate_entry_type}
      />
      <AffiliateModel
        disabled={disabled}
        register={register}
        name={affiliate_model}
      />
      {/*  <StoreCookieDuration
        disabled={disabled}
        register={register}
        name={cookie_duration}
      /> */}
      <CommissionRateField
        disabled={disabled}
        register={register}
        name={{ type: commission_type, amount: commission_amount }}
        default_value={default_values.commission_type}
      />
      <CommissionDisbursement
        disabled={disabled}
        register={register}
        name={{ time: time, time_unit: time_unit }}
        default_value={{
          time_unit: default_values.time_unit,
          time: default_values.time,
        }}
      />
      <AffiliateTermsAndConditions
        disabled={disabled}
        register={register}
        name={terms_and_conditions}
        setValue={setValue}
        initial_value={default_values.terms_and_conditions}
      />
    </div>
  );
}

export default ProductSettingsForm;
