import { useForm } from "react-hook-form";

import CommissionRateField from "./global_commission_settings/CommissionRateField";
import CommissionDisbursement from "./global_commission_settings/CommissionDisbursement";
import AffiliateEntryType from "./global_commission_settings/AffiliateEntryType";
import AffiliateModel from "./global_commission_settings/AffiliateModel";
import AffiliateTermsAndConditions from "./global_commission_settings/AffiliateTermsAndConditions";
//import StoreCookieDuration from "./global_commission_settings/StoreCookieDuration";
import {
  globalCommissionSettingsSchema,
  makeNewGlobalCommissionSettings,
} from "../../../schema/affiliate_schema";
import { useDispatch, useSelector } from "react-redux";
import { saveGlobalSettings } from "../../../store/storeSlice";
import { updateStoreSettings } from "../../../services/firebase/fbAffiliate";
import ReturnPeriodField from "./global_commission_settings/ReturnPeriodField";
import { useNavigate } from "react-router";
import { MILLI_IN_24_HOURS, root_routes } from "../../../utilty";
import AffiliateProgramScope from "./global_commission_settings/AffiliateProgramScope";
import { useRef } from "react";
import Tip from "../../design_system/Tips";

const {
  affiliate_entry_type,
  affiliate_model,
  commission_type,
  commission_amount,
  disbursement: { time, time_unit },
  // cookie_duration,
  maximum_return_period,
  is_store_wide,
  terms_and_conditions,
} = globalCommissionSettingsSchema;

const new_global_settings = makeNewGlobalCommissionSettings({});

function GlobalCommissionSettings({
  styles = { form: {} },
  classes = { form: "" },
}) {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store_id = useSelector((state) => state.brand_store.id);
  const globalCommissionSettings = useSelector(
    (state) => state.brand_store.global_settings ?? new_global_settings
  );

  const old_settings = useRef(globalCommissionSettings);
  const rate_revision_valid = (Date.now() - (globalCommissionSettings.timestamps.last_rate_revised_on ?? 0)) < MILLI_IN_24_HOURS;
  const tnc_revision_valid = (Date.now() - (globalCommissionSettings.timestamps.last_tnc_revised_on ?? 0)) < MILLI_IN_24_HOURS;

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: { ...new_global_settings, ...globalCommissionSettings },
  });

  const onSubmit = async (data) => {
    console.log(data);
    await updateStoreSettings({ id: store_id, settings: data });
    dispatch(saveGlobalSettings(data));
    navigate(root_routes.settings);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      id="CommissionSettingForm"
      className={`${classes.form} Affiliate-settings-form`}
      style={styles.form}
    >

      <AffiliateProgramScope register={register} name={is_store_wide} />
      <ReturnPeriodField register={register} name={maximum_return_period} />
      <AffiliateEntryType register={register} name={affiliate_entry_type} />

      {rate_revision_valid && <div style={{ marginTop: "24px", marginBottom: "0px" }}><Tip classes={{ container: "attention fw-500" }}>Wait for at least 24 hours to edit commission settings again.</Tip></div>}
      <AffiliateModel disabled={rate_revision_valid} register={register} name={affiliate_model} />

      {/* <StoreCookieDuration register={register} name={cookie_duration} /> */}

      <CommissionRateField
        disabled={rate_revision_valid}
        register={register}
        name={{ type: commission_type, amount: commission_amount }}
        default_value={globalCommissionSettings.commission_type}
        current_commission={globalCommissionSettings.commission_amount}
        setCommission={setValue}
      />

      <CommissionDisbursement
        disabled={rate_revision_valid}
        register={register}
        name={{ time: time, time_unit: time_unit }}
        default_value={{
          time_unit: globalCommissionSettings.disbursement.time_unit,
          time: globalCommissionSettings.disbursement.time,
        }}
      />

      <AffiliateTermsAndConditions
        register={register}
        name={terms_and_conditions}
        setValue={setValue}
        initial_value={globalCommissionSettings.terms_and_conditions}
      />
    </form>
  );
}

export default GlobalCommissionSettings;
