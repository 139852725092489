import StoreDetailsForm from "../../../components/affiliate/store/StoreDetailsForm";
import { motion } from "framer-motion";
import { framer_configs } from "../../../utilty";
import { IconLarge } from "../../../components/design_system/Icons";
import { svg_icons } from "../../../services/variant_constants";
import { useNavigate } from "react-router";
import { H1 } from "../../../components/design_system/text/Heading";
import { TextIconButtonMedium } from "../../../components/design_system/Buttons";

function StoreDetailsPage() {
  /*   const store_created = useSelector(
    (state) => state.brand_store?.affiliate?.store_created
  ); */
  const navigate = useNavigate();
  return (
    <motion.div
      {...framer_configs.parent_framer_animations.stagger}
      className="Store-details-page"
    >
      <div style={{ width: "60%", marginLeft: "auto", marginRight: "auto" }} className="flex-row flex-align-center gap-10 mb-12">
        <IconLarge
          stroke_color="none"
          fill="#1f1f1f"
          icon={svg_icons.left_arrow_icon}
          onClick={() => navigate(-1)}
        />
        <H1>Store Details</H1>
      </div>
      <StoreDetailsForm />
    </motion.div>
  );
}

export default StoreDetailsPage;
