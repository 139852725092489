import { SVGIcon } from "../../assets/svg/icons/res";
import { bannerPropTypes } from "../../components/design_system/Banner";
import {
  TextButtonMedium,
  TextIconButtonMedium,
} from "../../components/design_system/Buttons";
import { SH1 } from "../../components/design_system/text/SubHeading";
import { openInNewTab } from "../../services/common";
import { icon_sizes, svg_icons } from "../../services/variant_constants";
import { settings_routes } from "../../utilty";

export const kyc_banner_props = (navigate) =>
  bannerPropTypes({
    Icon: (
      <SVGIcon
        size={icon_sizes.medium}
        icon={svg_icons.twinkle_blue}
        stroke_color="none"
        fill="var(--color-green-solid)"
      />
    ),
    Message: (
      <SH1 classes="fw-500">
        <span className="fc-green fw-600">Important: </span> Please complete
        your Brand’s KYC and Agreement.
      </SH1>
    ),
    Button: (
      <TextIconButtonMedium
        onClick={() => navigate("kyc")}
        classes="Btn-accent Btn-green"
        icon={svg_icons.products_menu_icon}
        icon_stroke={"#fff"}
      >
        Go to KYC & Agreement
      </TextIconButtonMedium>
    ),
    classes: {
      banner: "green flex center",
      container: "padding-32 mobile-background-blue",
    },
    styles: { container: { paddingBottom: 0 } },
    path_exceptions: ["kyc"],
  });

export const api_banner_props = (navigate) =>
  bannerPropTypes({
    Icon: (
      <SVGIcon
        size={icon_sizes.medium}
        icon={svg_icons.twinkle_blue}
        stroke_color="none"
        fill="var(--color-accent-solid)"
      />
    ),
    Message: (
      <SH1 classes="fw-500">
        <span className="fc-accent fw-600">Important: </span>Complete your
        store's integration
      </SH1>
    ),
    Button: (
      <TextIconButtonMedium
        onClick={() => navigate(settings_routes.api_overview.link)}
        classes="Btn-accent"
        icon={svg_icons.right_arrow_icon}
        icon_stroke={"none"}
        icon_fill={"#fff"}
      >
        Integrate Callbacks
      </TextIconButtonMedium>
    ),
    classes: {
      banner: "blue flex center",
      container: "padding-32 mobile-background-blue ",
    },
    styles: { container: { paddingTop: "12px" } },
    path_exceptions: ["api_overview"],
  });
