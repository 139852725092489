import { clearAffiliates } from "./affiliateSlice";
import { brand_logout } from "./brandSlice";
import { clearProducts } from "./productSlice";
import { clearStore } from "./storeSlice";

export function logout(dispatch) {
  dispatch(brand_logout());
  dispatch(clearStore());
  dispatch(clearProducts());
  dispatch(clearAffiliates());
}
