import { useState } from "react";
import BottomSheet from "./BottomSheet";
import { TextButtonMedium, TextIconButtonMedium } from "../Buttons";

function UploadImageSheet({
  sheet_props,
  getImage,
  image_width = 100,
  image_height = 100,
  image_size = 500,
  classes = { preview_image: "" },
  styles = { preview_image: {} },
  only_link = false,
  only_file = false,
}) {
  const [_imageURL, _setImageURL] = useState(null);
  const [_imageFile, _setImageFile] = useState(null);
  const [_error, _setError] = useState(null);

  const validateImageURL = () => {
    alert("Oops! Invalid image link");
    resetFile();
  };

  const validateUplaodedFile = (image_file) => {
    const size_validation = image_size * 1000;
    /*console.log(image_file.size);
    console.log(size_validation); */
    _setError({
      size: image_file.size > size_validation,
      file_type: !image_file.type.includes("image/"),
    });
    if (
      image_file.size > size_validation ||
      !image_file.type.includes("image/")
    )
      return false;
    return true;
  };

  const handleImageFileInput = (e) => {
    const image_file = e.target.files[0];

    if (!validateUplaodedFile(image_file)) return 0;

    _setImageFile(image_file);

    const file_reader = new FileReader();
    file_reader.readAsDataURL(image_file);

    file_reader.addEventListener("load", () => {
      _setImageURL(file_reader.result);
    });

    return file_reader.removeEventListener("load", () => {
      _setImageURL(file_reader.result);
    });
  };

  const handleImageLinkInput = (e) => {
    _setImageURL(e.target.value);
    _setImageFile(null);
  };

  const resetFile = () => {
    _setImageURL(null);
    _setImageFile(null);
  };

  const display_size =
    image_size < 1024
      ? image_size + " Kb"
      : Math.floor(image_size / 1024) + " Mb";

  return (
    <BottomSheet title={"Upload Image"} {...sheet_props}>
      {_imageURL === null ? (
        <>
          {!only_file && (
            <input
              className="Input-text"
              onChange={handleImageLinkInput}
              placeholder={"Paste a link to your image"}
            />
          )}
          {!only_link && !only_file && (
            <hr
              style={{
                margin: "16px 0",
                borderColor: "var(--color-pb-10)",
                borderBottomWidth: "0px",
              }}
            />
          )}
          {!only_link && (
            <>
              {" "}
              <label
                className="Btn Btn-secondary Btn-text-medium Btn-text"
                htmlFor="image_file"
              >
                Or choose file from local storage
              </label>
              <input
                onInput={handleImageFileInput}
                type="file"
                name="image_file"
                id="image_file"
                hidden
              />
            </>
          )}
          <ul
            className={`Upload-image-suggestions text-align-left ${only_link ? "mt-12" : ""
              }`}
          >
            {!only_link && (
              <>
                <li className={_error?.size ? "fc-danger-text" : ""}>
                  File should be less than {display_size}
                </li>
                <li className={_error?.file_type ? "fc-danger-text" : ""}>
                  File should in jpeg or png format{" "}
                </li>
              </>
            )}
            <li>
              Recommended dimensions are {image_width}px x {image_height}px
            </li>
          </ul>
        </>
      ) : (
        <>
          <img
            onError={validateImageURL}
            style={{
              ...styles.preview_image,
            }}
            src={_imageURL}
            className={`Upload-image-preview ${classes.preview_image}`}
            alt="Preview"
          />

          <TextButtonMedium classes="Btn-full" onClick={resetFile}>
            Change Image
          </TextButtonMedium>
          <TextIconButtonMedium
            onClick={() => getImage({ url: _imageURL, file: _imageFile })}
            classes="Btn-accent Btn-full"
          >
            Upload Image
          </TextIconButtonMedium>
        </>
      )}
    </BottomSheet>
  );
}

export default UploadImageSheet;
