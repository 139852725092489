import { svg_icons } from "../../../services/variant_constants";
import { SH2 } from "../text/SubHeading";
import { IconMedium } from "../Icons";

export function InputHeaderTextWithIcon({
  icon_props,
  children,
  classes = { container: "", text: "", icon: "" },
  styles = { container: {}, text: {}, icon: {} },
}) {
  return (
    <div
      style={styles.container}
      className={`Input-label-with-icon ${classes.container}`}
    >
      <SH2 styles={styles.text} classes={`${classes.text}`}>
        {children}
      </SH2>
      <IconMedium
        style={styles.icon}
        classes={`${classes.icon}`}
        icon={svg_icons.info_icon}
        {...icon_props}
      />
    </div>
  );
}
