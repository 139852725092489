import { SH2 } from "../text/SubHeading";
import BottomSheet from "./BottomSheet";

function UploadingLoaderSheet({ children }) {
  return (
    <BottomSheet _bottomSheetVisible={true}>
      {children ?? <SH2>Loading..</SH2>}
    </BottomSheet>
  );
}

export default UploadingLoaderSheet;
