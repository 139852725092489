import { useSelector } from "react-redux";
import AffiliateListRow from "./AffiliateListRow";

function AffiliateList() {
  //use loader data based on query or use selector
  const affiliates = useSelector((state) => state.affiliates);
  return (
    <ul className="Affiliate-list">
      <li className="Affiliate-list-row">
        <p className="fw-500 SH1">Affiliate name</p>
        <p className="fw-500 SH1">Traffic</p>
        <p className="fw-500 SH1">No. of Sales</p>
        <p className="fw-500 SH1">Revenue</p>
      </li>
      {Object.values(affiliates).map((affiliate) => {
        return (
          <AffiliateListRow key={affiliate.id} affiliate_id={affiliate.id} />
        );
      })}
    </ul>
  );
}

export default AffiliateList;
