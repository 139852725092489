import { useNavigate } from "react-router";
import { TextIconButtonMedium } from "../../components/design_system/Buttons";
import { P2 } from "../../components/design_system/text/BodyText";
import { H1, H3 } from "../../components/design_system/text/Heading";
import { SH2 } from "../../components/design_system/text/SubHeading";
import { svg_icons } from "../../services/variant_constants";
import { welcome_routes } from "../../utilty";
import { motion } from "framer-motion";

const feature_ul_framer_props = {
  initial: "start",
  animate: "visible",
  variants: {
    start: {},
    visible: { transition: { delayChildren: 0.5, staggerChildren: 0.2 } },
  },
};

const feature_li_framer_props = {
  variants: {
    start: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  },
};

const fade_enter_up_framer = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.5 },
};

function Onboarding() {
  const navigate = useNavigate();
  const feature_list = [
    {
      title: "Enable Affiliate Program",
      subtitle:
        "Connect your existing E-Commerce store wth us in three easy steps.",
    },
    {
      title: "Virality for your products",
      subtitle:
        "Thousands of affiliates to share your products with a single click.",
    },
    {
      title: "Transparent transactions",
      subtitle:
        "Pay commissions only for purchases which haven't been returned.",
    },
  ];
  return (
    <div className="Brand-ob-page">
      <motion.div {...fade_enter_up_framer}>
        <H1 classes="fw-500">Welcome to SocialPi..!</H1>
        <H3 classes="fc-pb-60">
          Seamless Affiliate Program Creation & Management <br />
          for your E Commerce Store
        </H3>
      </motion.div>

      <motion.div {...fade_enter_up_framer} className="Ob-slider-background">
        <div className="Slider First"></div>
        <div className="Slider Second"></div>
      </motion.div>
      <motion.ul {...feature_ul_framer_props} className="Brand-ob-feature-list">
        {feature_list.map((feature, index) => {
          const { title, subtitle } = feature;
          return (
            <motion.li
              {...feature_li_framer_props}
              key={index}
              className="Brand-ob-feature"
            >
              <SH2 classes="fw-500">{title}</SH2>
              <P2 classes={"mt-12"}>{subtitle}</P2>
            </motion.li>
          );
        })}
      </motion.ul>
      <TextIconButtonMedium
        icon={svg_icons.round_tick}
        icon_stroke={"#fff"}
        classes="Btn-full Btn-broad margin-auto-h"
        onClick={() => navigate(welcome_routes.profile.link)}
      >
        Let's Get Started
      </TextIconButtonMedium>
    </div>
  );
}

export default Onboarding;
