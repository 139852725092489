function InputFieldText({
  type = "text",
  input_mode = "text",
  classes = "",
  styles = {},
  register = () => {},
  name,
  placeholder,
  error,
  disabled = false,
}) {
  return (
    <input
      style={{ ...styles }}
      className={`${classes} Input-text ${error ? "Input-text-error" : ""}`}
      type={type}
      inputMode={input_mode}
      placeholder={placeholder}
      {...register(name)}
      disabled={disabled}
    />
  );
}

export default InputFieldText;
