import { useQuill } from "react-quilljs";
import InputFieldArea from "../../../design_system/forms/InputFieldArea";
import { InputHeaderTextWithIcon } from "../../../design_system/forms/InputGroupHeader";

function AffiliateTermsAndConditions({
  initial_value,
  setValue,
  register,
  name,
  disabled = false,
}) {
  const { quill, quillRef } = useQuill({
    placeholder: "Tell us more about your store!",
    modules: {
      toolbar: [
        [
          "bold",
          "italic",
          "underline",
          { list: "ordered" },
          { list: "bullet" },
          { align: [] },
        ],
      ],
    },
  });

  return (
    <div data-disabled={disabled} className="Affiliate-settings-input-group">
      <InputHeaderTextWithIcon
        classes={{ container: "mb-16" }}
        icon_props={{ fill: "none", stroke_color: "#1f1f1f" }}
      >
        Affiliate Terms and Conditions
      </InputHeaderTextWithIcon>
      <InputFieldArea
        quill={quill}
        quillRef={quillRef}
        register={register}
        name={name}
        initial_value={initial_value}
        setValue={setValue}
        disabled={disabled}
      />
      {/* <textarea
        disabled={disabled}
        style={{ height: "200px" }}
        className="Input-text"
        {...register(name)}
      /> */}
    </div>
  );
}

export default AffiliateTermsAndConditions;
