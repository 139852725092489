export const makeNewTimestamps = ({
  created_on = Date.now(),
  updated_on = null,
  blocked_on = null,
  deleted_on = null,
  last_reported_on = null,
  last_active = null,
}) => {
  return {
    created_on,
    updated_on,
    deleted_on,
    blocked_on,
    last_reported_on,
    last_active,
  };
};

export const timestamps_schema = {
  root: "timestamps",
  created_on: "timestamps.created_on",
  updated_on: "timestamps.updated_on",
  blocked_on: "timestamps.blocked_on",
  deleted_on: "timestamps.deleted_on",
  last_reported_on: "timestamps.last_reported_on",
  last_active: "timestamps.last_active",
};

export const makeNewStatuses = ({
  status = "active",
  is_active = true,
  is_blocked = false,
  is_reported = true,
  times_reported = 0,
  last_reprted_by = null,
  is_deleted = false,
  deleted_by = null,
  blocked_by = null,
}) => {
  return {
    status,
    is_active,
    is_blocked,
    is_deleted,
    is_reported,
    last_reprted_by,
    times_reported,
    blocked_by,
    deleted_by,
  };
};
