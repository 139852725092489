import InputError from "../../../design_system/forms/InputError";
import InputFieldText from "../../../design_system/forms/InputFieldText";
import InputLabelText from "../../../design_system/forms/InputLabels";

function StoreName({ register, name, error }) {
  return (
    <div>
      <InputLabelText>Add your store name</InputLabelText>
      <InputFieldText
        name={name}
        placeholder={"Eg. Bewakoof etc."}
        register={register}
        error={error}
      />
      <InputError>{error}</InputError>
    </div>
  );
}

export default StoreName;
