import "./App.css";
import "./styles/global.css";
import "./styles/Common.css";
import "./styles/DesignSystem.css";
import "./styles/utility.css";

import { RouterProvider } from "react-router";
import { router } from "./routes";

function App() {
  return <RouterProvider router={router} />;
}

export default App;
