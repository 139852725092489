import { useNavigate } from "react-router";
import { TextIconButtonMedium } from "../../../components/design_system/Buttons";
import InputLabelText from "../../../components/design_system/forms/InputLabels";
import { H1 } from "../../../components/design_system/text/Heading";
import {
  makeNewStoreProduct,
  makeNewStoreProductDetails,
} from "../../../schema/affiliate_schema";
import { scrapeBrandProductDetail } from "../../../services/API/cloudFunctions";
import { svg_icons } from "../../../services/variant_constants";
import { product_routes } from "../../../utilty";
import { useSelector } from "react-redux";
import WithLoader, {
  useWithLoader,
} from "../../../components/Wrappers/LoaderWrapper";
import { fetchStoreProductWithParam } from "../../../services/firebase/fbAffiliate";

function AddNewProductWithLink() {
  const navigate = useNavigate();
  const brand = useSelector((state) => state.brand.id);
  const store = useSelector((state) => state.brand_store.id);
  const store_link = useSelector((state) => state.brand_store.link);

  const loader = useWithLoader(false);

  const validateProductDomain = async (input) => {
    const input_domain = new URL(input).hostname;
    const store_domain = new URL(store_link).hostname;
    if (input_domain !== store_domain) return false;
    else return true;
  };
  const handleLinkInput = async (e) => {
    e.preventDefault();
    const input_link = e.target[0].value.split("?")[0];
    loader.startLoading();
    loader.setError(false);
    //Check domain of product with store domain.
    const is_domain_valid = await validateProductDomain(input_link);

    if (!is_domain_valid) {
      const input_domain = new URL(input_link).hostname;
      const store_domain = new URL(store_link).hostname;
      loader.endLoading();
      loader.setError(
        <>
          Your product domain should match your store domain.
          <br /> <br />
          Entered product domain: <br />
          <span className="fw-500 fc-danger-text">{input_domain}</span>
          <br />
          Your store domain: <br />
          <span className="fw-500 fc-danger-text">{store_domain}</span>
        </>
      );
      return 0;
    }

    const does_product_exist = await fetchStoreProductWithParam({
      param: "link",
      value: input_link,
    });

    if (does_product_exist.length) {
      loader.endLoading();
      loader.setError("Your store already has a product with this link!");
      return 0;
    }

    const page_data = await scrapeBrandProductDetail(input_link);
    console.log(page_data);
    if (page_data.error) {
      loader.endLoading();
      loader.setError(page_data.message);
      return 0;
    }
    if (page_data) {
      const { title, description, media, tags } = page_data.data;
      const product_details = makeNewStoreProductDetails({
        title,
        description,
        tags: tags ? [tags] : [],
        media: media ? [...media] : [],
      });
      const store_product = makeNewStoreProduct({
        brand: brand,
        store: store,
        link: input_link,
        product_details: product_details,
      });
      navigate(product_routes.product_detail.link, {
        state: store_product,
      });
    }
  };
  return (
    <div className="Product-detail-page-only-link">
      <H1>Let's add an awesome product for your affiliates</H1>
      <form className="Product-link-form" onSubmit={handleLinkInput}>
        <InputLabelText>Paste product link here </InputLabelText>
        <input
          placeholder="Paste your product link here"
          type="url"
          className="Input-text"
          required
        />
        <WithLoader _loading={loader._loading} _error={loader._error}>
          <TextIconButtonMedium
            classes="Btn-accent Btn-full"
            icon={svg_icons.right_arrow_icon}
            icon_fill={"#fff"}
            icon_stroke={"none"}
            type="submit"
          >
            Continue
          </TextIconButtonMedium>
        </WithLoader>
      </form>
    </div>
  );
}

export default AddNewProductWithLink;
