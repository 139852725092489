import { commission_types } from "../../../../meta"
import { getAffiliateCut, getDecimalPlaces } from "../../../../utilty"
import { TextIconButtonMedium } from "../../../design_system/Buttons";
import { P2, P3 } from "../../../design_system/text/BodyText";
import { SH2, SH3 } from "../../../design_system/text/SubHeading"

function CommissionRateTip({ commission_amount, commission_type, setCommission }) {
    console.log(commission_amount)
    const actual_cut = commission_type === commission_types.fixed_amount ? "Rs." + commission_amount : commission_amount + "%";
    const affiliate_cut = getAffiliateCut(commission_amount).rounded;
    const commission_text = commission_type === commission_types.fixed_amount ? "Rs." + affiliate_cut : affiliate_cut + "%";
    const recalculated_commission = getDecimalPlaces((parseFloat(commission_amount) / 0.75));

    return <div className="Commission-rate-tip-container">
        <SH3 classes="fc-accent fw-500">Affiliate will receive {commission_text} in commission</SH3>
        <P3 classes="fc-pb-60"><span className="fc-pb fw-600">Tip:</span> If you want the affiliate to receive {actual_cut} then you will have to set the commission to {recalculated_commission}</P3>
        {/* <TextIconButtonMedium>
            Ok, set commission to {recalculated_commission}
        </TextIconButtonMedium> affiliate will receiev 75% */}
    </div>
}

export default CommissionRateTip