import { SH1, SH2, SH3 } from "../../components/design_system/text/SubHeading";

function DesignSystemSubheadings() {
  return (
    <>
      <div>
        <SH1 classes="text-underline">Sub Heading 1</SH1>
        <SH2>Sub Heading 2</SH2>
        <SH3>Sub Heading 3</SH3>
      </div>
      <div>
        <SH1 classes="fw-500 text-underline">Sub Heading 1</SH1>
        <SH2 classes="fw-500">Sub Heading 2</SH2>
        <SH3 classes="fw-500 ">Sub Heading 3</SH3>
      </div>
    </>
  );
}

export default DesignSystemSubheadings;
