import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { SH1, SH3 } from "../../components/design_system/text/SubHeading";
import {
  brand_profile_schema,
  makenewBrandProfile,
} from "../../schema/users_schema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputLabelText from "../../components/design_system/forms/InputLabels";
import { TextIconButtonMedium } from "../../components/design_system/Buttons";
import { svg_icons } from "../../services/variant_constants";
import { InputHeaderTextWithIcon } from "../../components/design_system/forms/InputGroupHeader";
import { RadioInput } from "../../components/design_system/forms/RadioInput";
import { completeProfile } from "../../store/brandSlice";
import { useNavigate } from "react-router";
import { mobile_regexp, no_zero_start, store_routes } from "../../utilty";
import { saveBrandProfile } from "../../services/firebase/fbAuth";
import * as yup from "yup";
import InputError from "../../components/design_system/forms/InputError";

const itemVariants = {
  start: { opacity: 0, y: 20 },
  end: {
    opacity: 1,
    y: 0,
  },
};

const {
  brand_name,
  brand_poc: {
    name,
    contact: { email, mobile },
  },
  running_other_affiliate_program,
  store_types: { shopify, wordpress, dukaan, magento, custom, others },
  is_profile_complete,
} = brand_profile_schema;

const brand_schema = yup.object().shape({
  [brand_name]: yup
    .string()
    .required("Please fill your brand's name")
    .typeError("Invalid brand name")
    .transform((value) => {
      return value?.trim();
    }),
  [running_other_affiliate_program]: yup.bool().transform((value) => {
    if (value === "true") return true;
    return false;
  }),
  brand_poc: yup.object().shape({
    name: yup
      .string()
      .required("Please fill a POC name")
      .test({
        name: "name yup test",
        test: (value, ctx) => {
          for (let i = 0; i < value.length; i++) {
            const char = value[i].charCodeAt();
            if (
              (char >= 33 && char <= 64) ||
              (char >= 91 && char <= 96) ||
              (char >= 123 && char <= 126)
            )
              return ctx.createError({ message: "Invalid Name" });
          }
          return true;
        },
      })
      .min(2, "Oops! Names are usually more than two characters")
      .typeError("Invalid name")
      .transform((value) => {
        return value?.trim();
      }),
    contact: yup.object().shape({
      email: yup
        .string()
        .required("Please fill an email for the POC")
        .email("Invalid Email"),
      mobile: yup
        .string()
        .required("Please fill a mobile for the POC")
        .matches(no_zero_start, "Number can't start with a 0")
        .matches(mobile_regexp, "Invalid number")
        .length(10, "Mobile should be 10 digits"),
    }),
  }),
  /* [name]: yup
    .string()
    .required("Please fill a POC name")
    .matches(name_regexp, "Invalid Name")
    .min(2, "Oops! Names are usually more than two characters")
    .typeError("Invalid name"), */
});

function BrandProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const brand_id = useSelector((state) => state.brand.id);
  const brand_profile =
    useSelector((state) => state.brand.profile) ?? makenewBrandProfile({});

  const onSubmit = async (data) => {
    console.log(data);
    const saved_profile = await saveBrandProfile({
      brand_id: brand_id,
      profile: { ...data, [is_profile_complete]: true },
    });
    dispatch(completeProfile(saved_profile.profile));
    navigate(store_routes.basic_details.link);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...brand_profile,
      [running_other_affiliate_program]: "false",
    },
    resolver: yupResolver(brand_schema),
  });

  return (
    <motion.div
      initial={"start"}
      animate={"end"}
      transition={{
        staggerChildren: 0.2,
        type: "spring",
        bounce: 10,
        stiffness: 260,
        damping: 20,
      }}
      className="Brand-profile-page"
    >
      <motion.div variants={itemVariants} className="Brand-profile-page-header">
        <SH1 classes="fw-500">It's wonderful to have you here!</SH1>
        <SH3 classes="mt-8 mb-16">
          Let's get started with some basic information about your brand
        </SH3>
      </motion.div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <motion.div
          variants={itemVariants}
          className="Brand-profile-input-group"
        >
          <div className="Brand-profile-input-field">
            <InputLabelText>What's your brand called?</InputLabelText>
            <input
              className={`Input-text ${errors[brand_name] && "Input-text-error"
                }`}
              type="text"
              placeholder="Brand Name"
              {...register(brand_name)}
            />
            <InputError>{errors[brand_name]?.message}</InputError>
          </div>

          <InputLabelText classes="mt-20 mb-16">
            Where are you running your stores?
          </InputLabelText>
          <div className="Checkbox-input-group">
            <div className="Checkbox">
              <input
                className="Input-checkbox"
                name="shopify"
                type="checkbox"
                {...register(shopify)}
              />
              <label htmlFor="shopify" className="SH2">
                Shopify
              </label>
            </div>
            <div className="Checkbox">
              <input
                className="Input-checkbox"
                name="wordpress"
                type="checkbox"
                {...register(wordpress)}
              />
              <label htmlFor="shopify">Wordpress</label>
            </div>
            <div className="Checkbox">
              <input
                className="Input-checkbox"
                name="dukaan"
                type="checkbox"
                {...register(dukaan)}
              />
              <label htmlFor="magento">Dukaan</label>
            </div>
            <div className="Checkbox">
              <input
                className="Input-checkbox"
                name="magento"
                type="checkbox"
                {...register(magento)}
              />
              <label htmlFor="magento">Magento</label>
            </div>
            <div className="Checkbox">
              <input
                className="Input-checkbox"
                name="custom"
                type="checkbox"
                {...register(custom)}
              />
              <label htmlFor="magento">Custom Built</label>
            </div>
            <div className="Checkbox">
              <input
                className="Input-checkbox"
                name="others"
                type="checkbox"
                {...register(others)}
              />
              <label htmlFor="magento">Others</label>
            </div>
          </div>

          <InputLabelText classes="mt-20 mb-16">
            Are you running any other affiliate programs
          </InputLabelText>
          <div className="Brand-profile-input-field">
            <RadioInput
              value={"true"}
              name={running_other_affiliate_program}
              register={register}
            >
              Yes
            </RadioInput>
            <RadioInput
              value={"false"}
              name={running_other_affiliate_program}
              register={register}
            >
              No
            </RadioInput>
          </div>
        </motion.div>

        <motion.div
          variants={itemVariants}
          className="Brand-profile-input-group"
        >
          <InputHeaderTextWithIcon>
            Kindly fill your Brand's Point of Contact's (POC's) Details
          </InputHeaderTextWithIcon>
          <div className="Brand-profile-input-field">
            <InputLabelText>Name</InputLabelText>
            <input
              className={`Input-text ${errors.brand_poc?.name && "Input-text-error"
                }`}
              type="text"
              placeholder="Name of POC"
              {...register(name)}
            />
            <InputError>{errors.brand_poc?.name?.message}</InputError>
          </div>
          <div className="Brand-profile-input-field">
            <InputLabelText>E-mail</InputLabelText>
            <input
              className={`Input-text ${errors.brand_poc?.contact?.email && "Input-text-error"
                }`}
              type="text"
              placeholder="Email of POC"
              {...register(email)}
            />
            <InputError>{errors.brand_poc?.contact?.email?.message}</InputError>
          </div>
          <div className="Brand-profile-input-field">
            <InputLabelText>Contact Number</InputLabelText>
            <input
              className={`Input-text ${errors.brand_poc?.contact?.mobile && "Input-text-error"
                }`}
              type="text"
              placeholder="Mobile Number of POC"
              {...register(mobile)}
            />
            <InputError>
              {errors.brand_poc?.contact?.mobile?.message}
            </InputError>
          </div>
        </motion.div>
        <TextIconButtonMedium
          classes="mt-24 Btn-full Btn-broad margin-auto-h"
          icon={svg_icons.right_arrow_icon}
          icon_stroke={"none"}
          icon_fill={"#fff"}
          type="submit"
        >
          Continue
        </TextIconButtonMedium>
      </form>
    </motion.div>
  );
}

export default BrandProfile;
