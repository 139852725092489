import React from "react";
import {
  IconLarge,
  IconMedium,
  IconSmall,
} from "../../components/design_system/Icons";
import BottomSheet, {
  useBottomSheet,
} from "../../components/design_system/bottom_sheets/BottomSheet";
import { P1, P2, P3 } from "../../components/design_system/text/BodyText";

import { svg_icons } from "../../services/variant_constants";
import DesignSystemButtons from "./DesignSystemButtons";
import DesignSystemHeadings from "./DesignSystemHeadings";
import DesignSystemInputs from "./DesignSystemInputs";
import DesignSystemSubheadings from "./DesignSystemSubheadings";
//import UploadLoader from "../../components/common/UploadLoader";
import SelectMenu from "../../components/design_system/menus/SelectMenu";

function DesignSystemRoot() {
  const bottomSheetGeneral = useBottomSheet();

  return (
    <div className="App">
      <DesignSystemHeadings />
      <DesignSystemSubheadings />
      <div>
        <P1 classes="text-underline">Pararaph 1</P1>
        <P2>Pararaph 2</P2>
        <P3>Pararaph 3</P3>
      </div>
      <div>
        <IconSmall
          onClick={bottomSheetGeneral.viewSheet}
          icon={svg_icons.round_tick}
        />
        <IconMedium icon={svg_icons.round_tick} />
        <IconLarge icon={svg_icons.round_tick} />
      </div>
      <DesignSystemButtons />
      <DesignSystemInputs />
      {/* <UploadLoader /> */}
      <SelectMenu title={"Select"} />
      <BottomSheet {...bottomSheetGeneral} title={"Example Sheet"}>
        <p>I am a lovely message</p>
      </BottomSheet>
    </div>
  );
}

export default DesignSystemRoot;
