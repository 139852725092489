const is_viewport_mobile =
  Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) >
  600
    ? false
    : true;

/****************************
##############################
********* SVG RELATED ********
##############################
*****************************/
export const svg_icons = {
  round_tick: "round_tick",
  cross_icon: "cross_icon",
  plus_icon: "plus_icon",
  dashboard_menu_icon: "dashboard_menu_icon",
  store_menu_icon: "store_menu_icon",
  products_menu_icon: "products_menu_icon",
  affiliates_menu_icon: "affiliates_menu_icon",
  wallet_menu_icon: "wallet_menu_icon",
  settings_menu_icon: "settings_menu_icon",
  upload_icon: "upload_icon",
  right_arrow_icon: "right_arrow_icon",
  left_arrow_icon: "left_arrow_icon",
  info_icon: "info_icon",
  save_icon: "save_icon",
  trash_icon: "trash_icon",
  chevron_down_icon: "chevron_down_icon",
  settings_icon: "settings_icon",
  pencil_icon: "pencil_icon",
  trend_icon: "trend_icon",
  trend_down_icon: "trend_down_icon",
  logout_icon: "logout_icon",
  connection_icon: "connection_icon",
  cart_icon: "cart_icon",
  twinkle_blue: "twinkle_blue",
  alert_icon: "alert_icon",
  link_icon: "link_icon",
  copy_icon: "copy_icon",
  picture_icon: "picture_icon",
  reel_icon: "reel_icon",
};

export const icon_sizes = {
  small: is_viewport_mobile ? 18 : 20,
  medium: is_viewport_mobile ? 20 : 22,
  large: is_viewport_mobile ? 24 : 26,
};

export const icon_stroke_width = {
  small: 1.6,
  medium: 1.8,
  large: 2,
};
/****************************
##############################
****** VIEWPORT RELATED ******
##############################
*****************************/
export const viewport_types = {
  mobile_small: "mobile_small",
  mobile_large: "mobile_large",
  tablet: "tablet",
  /*mobile: "mobile",
  desktop: "desktop", */
  desktop_small: "desktop_small",
  desktop_large: "desktop_large",
};

export const viewport_metrics = {
  desktop_small: { min: 1280, max: 1440 },
  desktop_large: { min: 1440, max: 1921 },
  mobile_small: { min: 300, max: 330 },
  mobile_large: { min: 330, max: 600 },
  tablet: { min: 600, max: 1200 },
};
