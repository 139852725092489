export const root_routes = {
  design_system: "/design_system",
  dashboard: "/dashboard",
  welcome: "/welcome",
  store: "/store",
  products: "/products",
  settings: "/settings",
  affiliates: "/affiliates",
  wallet: "/wallet",
  api_integration: "/api_integration",
  login: "/login",
};

export const login_routes = {
  email_link: {
    link: root_routes.login + "/email_link",
    segment: "email_link",
  },
};

export const welcome_routes = {
  onboarding: {
    link: root_routes.welcome + "/onboarding",
    segment: "onboarding",
  },
  profile: {
    link: root_routes.welcome + "/profile",
    segment: "profile",
  },
};

export const api_integration_routes = {
  overview: {
    link: root_routes.api_integration + "/overview",
    segment: "overview",
  },
};

export const dashboard_routes = {
  analytics: "/analytics",
  affiliate_profile: "/affiliate_profile/:id",
};

export const store_routes = {
  basic_details: {
    link: root_routes.store + "/basic_details",
    segment: "basic_details",
  },
  media_kit: {
    link: root_routes.store + "/media_kit",
    segment: "media_kit",
  },
};

export const product_routes = {
  list: {
    link: root_routes.products + "/list",
    segment: "list",
  },
  product_detail: {
    link: root_routes.products + "/product_detail",
    segment: "product_detail",
  },
  add_product_with_link: {
    link: root_routes.products + "/add_product_link",
    segment: "add_product_link",
  },
};

export const affiliate_routes = {
  list: {
    link: root_routes.affiliates + "/list",
    segment: "list",
  },
};

export const settings_routes = {
  global_affiliate_settings: {
    link: root_routes.settings + "/global_affiliate_settings",
    segment: "global_affiliate_settings",
  },
  api_overview: {
    link: root_routes.settings + "/api_overview",
    segment: "api_overview",
  },
};

export const design_system_routes = {
  buttons: root_routes.design_system + "/buttons",
  text: root_routes.design_system + "/text",
};

export const framer_configs = {
  button_scale_animation: {
    whileHover: {
      scale: 1.05,
      transition: { type: "spring", bounce: 0, stiffness: 260, damping: 10 },
    },
    whileTap: { scale: 0.97 },
  },

  animation: {
    bounce_up: {
      initial: { y: 20, opacity: 0 },
      animate: { y: 0, opacity: 1 },
      transition: {
        type: "spring",
        bounce: 0,
        stiffness: 320,
        damping: 12,
      },
    },
  },

  parent_framer_animations: {
    stagger: {
      initial: "start",
      animate: "end",
      transition: {
        staggerChildren: 0.1,
      },
    },
  },

  variants: {
    bounce_up: {
      start: {
        opacity: 0,
        y: 20,
      },
      end: {
        opacity: 1,
        y: 0,
      },
    },
    bounce_scale: {
      start: {
        opacity: 0,
        scale: 0,
      },
      end: {
        opacity: 1,
        scale: 1,
      },
    },
  },
};

export const url_regexp = new RegExp(
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/
);

export const name_regexp = new RegExp(/[A-z ]/);
export const mobile_regexp = new RegExp(/^([0-9]+$)/);
export const no_zero_start = new RegExp(/(^[^0])/);

export const MILLI_IN_24_HOURS = 8640000;
export const MILLI_VALID = 8640000 * 7;

export const getDaysFromMilli = ({ milli }) => {
  return milli / (1000 * 60 * 60 * 24);
};

export const getHoursFromMilli = ({ milli }) => {
  return milli / (1000 * 60 * 60);
};

export const getMinutesFromMilli = ({ milli }) => {
  return milli / (1000 * 60);
};

export const onScriptLoad = (url) => {
  return new Promise((resolve, reject) => {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute("src", url);
    head.appendChild(script);
    script.onload = () => {
      return resolve(true);
    };
    script.onerror = (e) => {
      return reject("Script couldn't be loaded");
    };
  });
};

export const getDecimalPlaces = (number = 0, places = 2) => {
  if (!number.toString().includes(".")) return number;
  const integer = number.toString().split(".")[0];
  const decimals = number.toString().split(".")[1].slice(0, places);
  const float = integer + "." + decimals;
  return parseFloat(float);
};

export const getAffiliateCut = (commission, round = 2) => {
  const cut = commission * 0.75;
  const rounded = getDecimalPlaces(cut, round);
  return { cut, rounded };
};
