import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  plugins: {
    legend: {
      position: "chartArea",
      align: "start",
      labels: {
        color: "rgba(31, 31, 31, 0.80)",
        boxWidth: 18,
        boxHeight: 18,
        font: { size: "14px", family: "Poppins, sans-serif", weight: "400" },
      },
    },
    /*  colors: { forceOverride: true } */
  },
  scales: {
    x: {
      grid: {
        drawOnChartArea: false,
      },
      ticks: {
        font: {
          weight: "400",
          size: "12px",
          family: "Poppins, sans-serif",
        },
      },
    },
    y: {
      type: "linear",
      min: 0,
      max: 5000,
      ticks: {
        stepSize: 200,
        font: {
          weight: "400",
          size: "12px",
          family: "Poppins, sans-serif",
        },
      },

      display: true,
      position: "left",
      grid: {
        drawOnChartArea: false,
      },
    },
    y1: {
      type: "linear",
      min: 0,
      max: 20,
      ticks: {
        stepSize: 5,
        font: {
          weight: "400",
          size: "12px",
          family: "Poppins, sans-serif",
        },
      },
      display: true,
      position: "right",
      grid: {
        drawOnChartArea: false,
      },
    },
  },
};

const labels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const data = {
  labels,
  datasets: [
    {
      label: "Revenue",
      data: [],
      borderColor: "#154DDD",
      backgroundColor: "#154DDD",
      yAxisID: "y",
    },
    {
      label: "Items Sold",
      data: [],
      borderColor: "#F57D0E",
      backgroundColor: "#F57D0E",
      yAxisID: "y1",
    },
  ],
};
function RevenueChart() {
  return <Line options={options} data={data} />;
}

export default RevenueChart;
