import InputError from "../../../design_system/forms/InputError";
import InputFieldText from "../../../design_system/forms/InputFieldText";
import InputLabelText from "../../../design_system/forms/InputLabels";

function StoreLink({ disabled, register, name, error }) {
  return (
    <div>
      <InputLabelText>Add your store link</InputLabelText>
      <InputFieldText
        disabled={disabled}
        name={name}
        placeholder={"Eg. www.bewakoof.com etc."}
        register={register}
        error={error}
      />
      <InputError>{error}</InputError>
    </div>
  );
}

export default StoreLink;
