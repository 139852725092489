import { useEffect, useState } from "react";
import { getViewportType } from "../services/common";

export const useWindowWidth = () => {
  const [_width, _setWidth] = useState(window.innerWidth);
  const [_viewportType, _setViewportType] = useState(
    getViewportType(window.innerWidth)
  );

  //Event listener handler for resize
  const changeWidth = () => {
    _setWidth(window.innerWidth);
    _setViewportType(getViewportType(window.innerWidth));
  };

  //Effect for initiliazing event listeners for resize
  useEffect(() => {
    window.addEventListener("resize", changeWidth);
    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return {
    window_width: _width,
    is_mobile: _width <= 600 ? true : false,
    viewport_type: _viewportType,
  };
};

export const useWindowHeight = () => {
  const [_height, _setHeight] = useState(window.innerHeight);

  const changeHeight = () => {
    _setHeight(window.innerHeight);
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight * 0.01}px`
    );
  };

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight * 0.01}px`
    );
    window.addEventListener("resize", changeHeight);
    return () => {
      window.removeEventListener("resize", changeHeight);
    };
  }, []);

  return { height: _height };
};
