import { svg_icons } from "../../../../../services/variant_constants";
import { tipPropTypes } from "../../../../design_system/Tips";

export const tip_props = tipPropTypes({
  header_visible: false,
  header_icon_props: {
    icon: svg_icons.alert_icon,
    stroke_color: "var(--color-yellow-solid)",
  },
  title: "Age Restricted",
  classes: { container: "attention mt-16" },
});

export const disclaimer_details =
  "Select this option if your store has products or visuals which may not be appropriate for our young audiences. (Sexual health products or toys, sensual or bold photography etc.)";

export const adult_categories = [
  "Adult Entertainment",
  "Sexual Wellness and Adult Toys",
];

export const regulated_categories = [
  "Prescription Medications",
  "Wildlife and Animal Products",
  "Lotteries and Gambling",
];

export const prohibited = [
  "Pyramid Schemes",
  "Narcotic Drugs and Psychotropic Substances",
  "Tobacco and Vaping Products",
  "Cannabis",
  "Alcohol",
  "Weapons and Firearms",
  "Stolen Goods",
  "Hemp/CBD Products",
  "Human Organs and Body Parts",
  "Obscene and Adult Content",
  "Surrogacy Services",
  "Human Trafficking",
  "Fake Currency and Counterfeit Goods",
  "Hazardous Chemicals and Explosives",
  "Endangered Species and Their Products",
  "Raw Acids and Toxic Substances",
  "Any additional goods or services that are illegal in the countries where your company is located or that are intended for those countries.",
];

export const broad_prohibited_categories = [
  "Illegal products and services",
  "Services and goods that violate intellectual property rights",
  "Unfair, exploitative, or misleading goods and services",
  "Content and services for adults",
  "Gambling",
  "Marijuana",
];
