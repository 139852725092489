import { createContext, useCallback, useEffect, useRef, useState } from "react";
import { fb_auth } from "../../services/firebase";
import { useNavigate } from "react-router";
import { root_routes, store_routes, welcome_routes } from "../../utilty";
import {
  fetchStoreAffiliates,
  fetchStoreProducts,
  fetchStorebyBrand,
} from "../../services/firebase/fbAffiliate";
import { useDispatch } from "react-redux";
import { initStore } from "../../store/storeSlice";
import { initProducts } from "../../store/productSlice";
import { findBrandByEmail } from "../../services/firebase/fbAuth";
import { login } from "../../store/brandSlice";
import { initAffiliates } from "../../store/affiliateSlice";

export const AuthContext = createContext(null);

function AuthContextProvider({ children }) {
  const [_appAuth, _setAppAuth] = useState(false);
  const [_loading, _setLoading] = useState(false);
  const ref_app_auth = useRef(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initData = useCallback(async () => {
    const brand = await findBrandByEmail({ email: _appAuth.email });
    console.log(brand);
    if (!brand) {
      _setLoading(false);
      navigate(welcome_routes.onboarding.link);
      return 0;
    } else {
      dispatch(login(brand));
    }
    if (!brand.profile.is_profile_complete) {
      console.log("Taking you to onboarding");
      _setLoading(false);
      navigate(welcome_routes.onboarding.link);
      return 0;
    }
    if (!brand.affiliate.store_created) {
      console.log("Taking you to store creation");
      _setLoading(false);
      navigate(store_routes.basic_details.link);
      return 0;
    }
    const affiliate_store = await fetchStorebyBrand({ brand_id: brand.id });
    const affiliate_products = await fetchStoreProducts({
      store_id: affiliate_store.id,
    });

    const affiliations = await fetchStoreAffiliates({
      store_id: affiliate_store.id,
    });
    const all_data_fetched = [
      affiliate_store,
      affiliate_products,
      affiliations,
    ];

    if (all_data_fetched.length === 3) {
      dispatch(initStore(affiliate_store));
      dispatch(initProducts(affiliate_products));
      dispatch(initAffiliates(affiliations.affiliates));
      _setLoading(false);
    }
  }, [_appAuth.email, dispatch, navigate]);

  useEffect(() => {
    //console.log("In Auth Provider effect");
    if (!_appAuth)
      fb_auth.onAuthStateChanged((user) => {
        if (user && user !== _appAuth) {
          _setAppAuth(user);
          _setLoading(true);
          console.log("authenticated");
        } else navigate(root_routes.login);
      });
  }, [navigate, _appAuth]);

  useEffect(() => {
    //console.log("In Auth Provider effect trigger fb");
    if (_appAuth && _appAuth !== ref_app_auth.current) {
      ref_app_auth.current = _appAuth;
      console.log("Trigger firebase here");
      initData();
    }
  }, [_appAuth, initData]);

  return (
    <AuthContext.Provider value={_appAuth}>
      {_appAuth && !_loading ? children : <p>Loading...</p>}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;
