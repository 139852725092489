function Banner({ children = <></>,
    Icon = <></>,
    Message = <></>,
    Button = <></>,
    classes = { container: "", banner: "" },
    styles = { container: {}, banner: {} },
    path_exceptions = [],
    is_site_wide = true,

    onClick = () => { } }) {
    const location = window.location.origin + window.location.pathname;
    const container_classes = "Banner-container " + classes.container;
    const banner_classes = "Banner " + classes.banner;

    if (path_exceptions.filter((value) => location.includes(value)).length > 0)
        return null;
    return (
        <div style={styles.container} onClick={onClick} className={container_classes}>
            <div style={styles.banner} className={banner_classes}>
                {Icon}
                {Message}
                {children}
                {Button}
            </div>
        </div>
    )
}

export default Banner;

export const bannerPropTypes = ({
    Icon,
    Message,
    Button,
    children = <></>,
    classes = { container: "", banner: "", },
    styles = { container: {}, banner: {} },
    path_exceptions = [],
    is_site_wide = true, }) => {
    return {
        Icon,
        Message,
        Button,
        children,
        classes,
        styles,
        path_exceptions,
        is_site_wide,
    }
}