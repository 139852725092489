import { affiliate_entry_types } from "../../../../meta";
import { InputHeaderTextWithIcon } from "../../../design_system/forms/InputGroupHeader";
import { RadioInput } from "../../../design_system/forms/RadioInput";

function AffiliateEntryType({ register, name, disabled = false }) {
  return (
    <div data-disabled={disabled} className="Affiliate-settings-input-group">
      <InputHeaderTextWithIcon
        icon_props={{ fill: "none", stroke_color: "#1f1f1f" }}
      >
        Entry Type
      </InputHeaderTextWithIcon>
      <RadioInput
        classes={{ container: "mt-16" }}
        name={name}
        value={affiliate_entry_types.open_for_all}
        register={register}
      >
        Open for all
      </RadioInput>
      <RadioInput classes={{ container: "mt-16" }} disabled={true}>
        Invites Only (Coming Soon)
      </RadioInput>
    </div>
  );
}

export default AffiliateEntryType;
