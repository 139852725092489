import { useEffect, useState } from "react";
import InputLabelText from "../../design_system/forms/InputLabels";
import { useBottomSheet } from "../../design_system/bottom_sheets/BottomSheet";
import UploadImageSheet from "../../design_system/bottom_sheets/UploadImageSheet";

function ProductMediaInput({ Ref_media }) {
  const uploadMediaSheet = useBottomSheet();
  const [_media, _setMedia] = useState(Ref_media.current);

  const handleDeleteMedia = (media) => {
    _setMedia(_media.filter((item) => item !== media));
  };

  const handleAddMedia = (image) => {
    _setMedia([..._media, image.url]);
    uploadMediaSheet.hideSheet();
  };

  useEffect(() => {
    Ref_media.current = [..._media];
  }, [_media, Ref_media]);

  return (
    <div>
      {uploadMediaSheet._bottomSheetVisible && (
        <UploadImageSheet
          sheet_props={{ ...uploadMediaSheet }}
          only_link={true}
          getImage={handleAddMedia}
          image_width={200}
          image_height={200}
          styles={{
            preview_image: {
              width: "200px",
              aspectRatio: "1/1",
              borderRadius: "4px",
            },
          }}
        />
      )}
      <InputLabelText>Media</InputLabelText>

      <div className="Product-details-media-grid">
        {_media.map((media, index) => {
          return (
            <img
              onClick={() => handleDeleteMedia(media)}
              key={index}
              className="Product-details-media"
              src={media}
              alt="Product"
            />
          );
        })}
        {_media.length === 5 ? null : (
          <div
            onClick={() => uploadMediaSheet.viewSheet()}
            className="Product-details-media-upload"
          >
            Add from URL
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductMediaInput;
