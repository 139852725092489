import { H1, H2, H3, H4 } from "../../components/design_system/text/Heading";
function DesignSystemHeadings() {
  return (
    <>
      <div>
        <H1>Heading 1</H1>
        <H2>Heading 2</H2>
        <H3>Heading 3</H3>
        <H4>Heading 4</H4>
      </div>
      <div>
        <H1 classes="fw-500">Heading 1</H1>
        <H2 classes="fw-500">Heading 2</H2>
        <H3 classes="fw-500">Heading 3</H3>
        <H4 classes="fw-500">Heading 4</H4>
      </div>
      <div>
        <H1 classes="fw-600">Heading 1</H1>
        <H2 classes="fw-600">Heading 2</H2>
        <H3 classes="fw-600">Heading 3</H3>
        <H4 classes="fw-600">Heading 4</H4>
      </div>
      <div>
        <H1 classes="fw-700 fc-pi-gradient">Pi Accent H1</H1>
        <H2 classes="fw-700 fc-accent">Accent H2</H2>
        <H3 classes="fw-700 fc-accent-gradient">Accent H3</H3>
        <H4 classes="fw-700 fc-accent-gradient">Accent H4</H4>
      </div>
    </>
  );
}

export default DesignSystemHeadings;
