import { useState } from "react";
import { commission_types } from "../../../../meta";
import Dropdown from "../../../design_system/forms/Dropdown";
import { InputHeaderTextWithIcon } from "../../../design_system/forms/InputGroupHeader";
import CommissionRateTip from "./CommissionRateTip";

function CommissionRateField({
  register,
  name = { type: "", amount: "" },
  default_value,
  disabled = false,
  current_commission,
  setCommission
}) {
  const [_currentCommission, _setCurrentCommission] = useState(default_value);
  const [_currentAmount, _setCurrentAmount] = useState(current_commission);
  const handleAmountChange = (e) => {
    _setCurrentAmount(e.target.value)
  }
  const options = [
    {
      value: commission_types.fixed_percent,
      content: "Percent (%)",
    },
    {
      value: commission_types.fixed_amount,
      content: (
        <p>
          Amount (<span className="font-roboto fw-500">₹</span>)
        </p>
      ),
    },
  ];

  return (
    <div data-disabled={disabled} className="Affiliate-settings-input-group">
      <InputHeaderTextWithIcon
        classes={{ container: "mb-16" }}
        icon_props={{ fill: "none", stroke_color: "#1f1f1f" }}
      >
        Commission Rate
      </InputHeaderTextWithIcon>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          columnGap: "8px",
        }}
      >
        <Dropdown
          disabled={disabled}
          options={options}
          menu_title={"Commission Rate"}
          register={register}
          name={name.type}
          default_value={
            options.filter((option) => option.value === default_value)[0]
          }
        />
        <input
          onInput={handleAmountChange}
          disabled={disabled}
          className="Input-text"
          placeholder={15}
          type="number"
          min={2}
          step={0.01}
          {...register(name.amount)}
        />
      </div>
      {_currentAmount && <CommissionRateTip commission_type={_currentCommission} commission_amount={_currentAmount} setCommission={setCommission} />}
    </div>
  );
}

export default CommissionRateField;
