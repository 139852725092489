//import { IconButtonMedium } from "../../design_system/Buttons";
import { svg_icons } from "../../../../services/variant_constants";
import { SH3 } from "../../../design_system/text/SubHeading";
import { SVGIcon } from "../../../../assets/svg/icons/res";
import { useBottomSheet } from "../../../design_system/bottom_sheets/BottomSheet";
import UploadImageSheet from "../../../design_system/bottom_sheets/UploadImageSheet";
import { useState } from "react";
import { motion } from "framer-motion";
import { framer_configs } from "../../../../utilty";
import { useSelector } from "react-redux";
import UploadingLoaderSheet from "../../../design_system/bottom_sheets/UploadingLoaderSheet";
import { useImageStorage } from "../../../../hooks/UploadImageFBHook";
import { getStoreLogoStorage } from "../../../../services/firebase";

function StoreDetailsLogo({ Ref }) {
  const brand_id = useSelector((state) => state.brand.id);
  const uploadBottomSheet = useBottomSheet();
  const [_logo, _setLogo] = useState(
    useSelector((state) => state.brand_store.store_details?.logo) ?? null
  );

  const setAfterUpload = (url) => {
    Ref.current = url;
    _setLogo(url);
  };

  const _uploadToStorage = useImageStorage({
    config: {
      folder: getStoreLogoStorage({ brand: brand_id }),
      taskAfterUpload: setAfterUpload,
    },
  });

  const confirmLogoUpload = async (logo) => {
    uploadBottomSheet.hideSheet();
    if (logo.file) {
      _uploadToStorage.startUpload(logo.file);
    } else {
      setAfterUpload(logo.url);
    }
  };

  return (
    <>
      {uploadBottomSheet._bottomSheetVisible && (
        <UploadImageSheet
          getImage={confirmLogoUpload}
          sheet_props={{ ...uploadBottomSheet }}
          styles={{
            preview_image: {
              width: "160px",
              aspectRatio: 1 / 1,
              borderRadius: "100%",
              objectFit: "contain",
              objectPosition: "center",
              backgroundColor: "var(--color-pb-05)",
            },
          }}
        />
      )}
      {_uploadToStorage._isUploading && <UploadingLoaderSheet />}

      {!_logo ? (
        <motion.div
          variants={{ ...framer_configs.variants.bounce_up }}
          onClick={uploadBottomSheet.viewSheet}
          className="Store-logo-upload-placeholder image-upload-action"
        >
          <SVGIcon icon={svg_icons.upload_icon} size="30" />
          <SH3>Store Logo</SH3>
        </motion.div>
      ) : (
        <motion.img
          variants={{ ...framer_configs.variants.bounce_up }}
          onClick={uploadBottomSheet.viewSheet}
          className="Store-details-logo cursor-pointer"
          src={_logo}
          alt="Logo"
        />
      )}
    </>
  );
}

export default StoreDetailsLogo;
