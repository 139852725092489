import { IconMedium } from "./Icons";

function Tip({
    header_icon_props,
    header_visible = true,
    custom_header,
    title,
    children,
    styles = { container: {}, header: {}, content: {} },
    classes = { container: "", header: "", content: "" },
}) {
    return (
        <div
            style={styles.container}
            className={`Tip ${classes.container}`}
        >
            {header_visible === false ? null : (
                <div style={styles.header} className={`Tip-header ${classes.header}`}>
                    {header_icon_props ? <IconMedium {...header_icon_props} /> : null}
                    {title && <p className="Tip-title">{title}</p>}
                    {custom_header && <>{custom_header}</>}
                </div>
            )}
            <div style={styles.content} className={`Tip-content ${classes.content}`}>
                {children}
            </div>
        </div>
    );
}

export const tipPropTypes = ({ header_icon_props = { icon: "", stroke_color: "", fill: "" },
    header_visible = true,
    title = "",
    children = <></>,
    styles = { container: {}, header: {}, content: {} },
    classes = { container: "", header: "", content: "" }, }) => {
    return { header_icon_props, header_visible, title, children, styles, classes }
}

export default Tip;


