import { useSelector } from "react-redux"
import { TextIconButtonMedium } from "../../components/design_system/Buttons"
import { P1 } from "../../components/design_system/text/BodyText"
import { H1, H2 } from "../../components/design_system/text/Heading"
import { SH1 } from "../../components/design_system/text/SubHeading"
import { encryptSessionKey } from "../../services/API/emSigner/index"
import { openInNewTab } from "../../services/common"
import { svg_icons } from "../../services/variant_constants"
import { useGetAgreement } from "../../hooks/AgreementHook"
import WithLoader, { useWithLoader } from "../../components/Wrappers/LoaderWrapper"

const form_url = "https://docs.google.com/forms/d/e/1FAIpQLSefnA45XCZbUk0UqsURyNSJpn_eEi48zKCvYtiBuYjbX7yb6Q/viewform"
const agreement_url = "https://firebasestorage.googleapis.com/v0/b/socialpi-app.appspot.com/o/brand_kyc_template%2Fbrand_agreement_v1.pdf?alt=media&token=54889684-406f-403d-9e68-ca5ff8908204"
const emsigner_gateway_url = //"https://demosignergateway.emsigner.com/eMsecure/V3_0/Index";
    "https://gateway.emsigner.com/eMsecure/V3_0/Index";

function KYC() {
    const agreement_start = useWithLoader(false);
    const _s_brand_id = useSelector((state) => state.brand.id);
    const _s_store_id = useSelector((state) => state.brand_store.id);
    const _s_signatory = useSelector((state) => state.brand.agreement.signatory ?? null);
    const _s_agreement_signed = useSelector((state) => state.brand.agreement.signed ?? false);
    const _h_signed_agreement = useGetAgreement({ brand: _s_brand_id });

    const handleSigner = async () => {
        agreement_start.startLoading();
        const page = await encryptSessionKey({ signatory: _s_signatory, brand: _s_brand_id, store: _s_store_id });
        document.getElementById("Parameter1").setAttribute("value", page.Parameter1)
        document.getElementById("Parameter2").setAttribute("value", page.Parameter2)
        document.getElementById("Parameter3").setAttribute("value", page.Parameter3)
        document.getElementById("frmdata").submit();
        //await getBrandWiseLinkStats()
    }

    return <div className="Api-integration-page">
        <H1 classes="fw-500 mb-12">KYC and Agreement</H1>
        <div>
            <div className="Api-integration-header">
                <div style={{ minWidth: "fit-content" }}>
                    <SH1 classes="fw-600 fc-accent">Step-1</SH1>
                    {!_s_signatory ? <H2 classes="fw-500 mt-4">Complete Brand KYC</H2 > : <H2 classes="fw-500 mt-4">KYC Completed!</H2>}
                    {!_s_signatory && <P1 classes={"fc-pb-60"}>Verified within 24 hours</P1>}
                </div>
                {!_s_signatory && <TextIconButtonMedium onClick={() => openInNewTab(form_url)} styles={{ width: "40%" }} classes="Btn-accent" icon={svg_icons.products_menu_icon} icon_stroke={"#fff"}>
                    Start KYC
                </TextIconButtonMedium>}
            </div>
        </div>

        <div className="Api-integration-header">
            <div style={{ minWidth: "fit-content" }}>
                <SH1 classes={`fw-600 ${_s_signatory ? "fc-accent" : "fc-pb-40"}`}>Step-2 {!_s_signatory && <span className="fc-pb-40 SH3 fw-400">(Activates after KYC verification)</span>}</SH1>
                <H2 classes="fw-500 mt-4">{!_s_agreement_signed ? "E-Sign the Agreement" : "Agreement Signed!"}</H2>
                {!_s_agreement_signed && <P1 classes="fc-pb-80 mt-4 Btn-text-link"><span onClick={() => openInNewTab(agreement_url)}>Click to view the agreement</span></P1>}
                {_s_agreement_signed && <a href={_h_signed_agreement.agreement} download={"SocialPi Affiliate Brand Agreement"}> <TextIconButtonMedium classes={"Btn-accent"} icon={svg_icons.products_menu_icon} icon_stroke={"#fff"}>
                    Download Signed Agreement
                </TextIconButtonMedium></a>}
            </div>

            {!_h_signed_agreement.loading && !_s_agreement_signed && <WithLoader _loading={agreement_start._loading} enableSuccessPop={false}> <TextIconButtonMedium onClick={_s_signatory ? () => handleSigner() : () => { }} styles={{ width: "40%" }} classes={_s_signatory ? "Btn-accent" : "Btn-disabled"} icon={svg_icons.products_menu_icon} icon_stroke={_s_signatory ? "#fff" : "var(--color-pb-40)"}>
                E-Sign Agreement
            </TextIconButtonMedium></WithLoader>}
        </div>
        <form style={{ visibility: "hidden" }} action={emsigner_gateway_url} id="frmdata" method="post" encType="application/x-www-form-urlencoded">
            <input hidden readOnly id="Parameter1" name="Parameter1" value="" />
            <input hidden readOnly id="Parameter2" name="Parameter2" value="" />
            <input hidden readOnly id="Parameter3" name="Parameter3" value="" />
            <button type="submit" id="btnEsignKYC">E-Sign</button>
        </form>
    </div>
}

export default KYC