import { viewport_metrics, viewport_types } from "./variant_constants";

export function getViewPortWidth() {
  return Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
}

export const is_viewport_mobile = getViewPortWidth() < 600 ? true : false;

export function getViewportType(width) {
  for (var i in viewport_types)
    if (width >= viewport_metrics[i].min && width < viewport_metrics[i].max)
      return i;
}

export function openInNewTab(url) {
  window.open(url, "_blank", "noopener,noreferrer");
}

export function makeURLSearchParams(params) {
  return "?" + new URLSearchParams(params).toString();
}
