import { root_routes } from "../../utilty";
import { svg_icons, viewport_types } from "../../services/variant_constants";
import { NavLink } from "react-router-dom";
import { IconLarge } from "../design_system/Icons";
import logo_full from "../../assets/branding/logo_full.svg";
import logo_infinity from "../../assets/branding/logo_infinity.svg";
import { useContext } from "react";
import { ViewportContext } from "../Providers/ViewportProvider";
import { fb_auth } from "../../services/firebase";
import { motion } from "framer-motion";
import { logout } from "../../store/crossSliceFunctions";
import { useDispatch } from "react-redux";

const ul_framer_props = {
  initial: "start",
  animate: "visible",
  variants: {
    start: { x: -300 },
    visible: {
      x: 0,
      transition: {
        type: "spring",
        bounce: 0,
        stiffness: 300,
        damping: 20,
        duration: 0.5,
      },
    },
  },
};

const li_framer_props = {
  variants: {
    start: { opacity: 0, scale: 0 },
    visible: { opacity: 1, scale: 1, transition: { delay: 0.1 } },
  },
};

function DashboardMenu({ disabled = false }) {
  const dispatch = useDispatch();
  const viewport = useContext(ViewportContext);
  const only_icon_viewport = [
    viewport_types.desktop_small,
    viewport_types.tablet,
  ].includes(viewport.viewport_type);

  const handleLogout = () => {
    logout(dispatch);
    try {
      fb_auth.signOut();
    } catch (err) {
      console.log(err.message);
    }
  };

  const options = [
    {
      name: "Dashboard",
      icon: svg_icons.dashboard_menu_icon,
      link: root_routes.dashboard,
    },
    {
      name: "Store",
      icon: svg_icons.store_menu_icon,
      link: root_routes.store,
    },
    {
      name: "Products",
      icon: svg_icons.products_menu_icon,
      link: root_routes.products,
    },
    /*     {
          name: "Affiliates",
          icon: svg_icons.affiliates_menu_icon,
          link: root_routes.affiliates,
        }, */
    /*  {
      name: "Wallet",
      icon: svg_icons.wallet_menu_icon,
      link: root_routes.wallet,
    }, */
    {
      name: "Settings",
      icon: svg_icons.settings_menu_icon,
      link: root_routes.settings,
    },
  ];

  if (viewport.is_mobile) return null;
  return (
    <motion.ul {...ul_framer_props} className="Dashboard-menu">
      {only_icon_viewport ? (
        <motion.li
          variants={li_framer_props.variants}
          className="Dashboard-menu-header condensed"
        >
          <img className="SocialPi-logo" src={logo_infinity} alt="SocialPi" />
        </motion.li>
      ) : (
        <motion.li
          variants={li_framer_props.variants}
          className="Dashboard-menu-header"
        >
          <img className="SocialPi-logo" src={logo_full} alt="SocialPi" />
        </motion.li>
      )}
      {options.map((option) => {
        return (
          <motion.li variants={li_framer_props.variants} key={option.name}>
            <NavLink
              onClick={(e) => {
                if (disabled) e.preventDefault();
              }}
              to={option.link}
              className={({ isActive, isPending }) =>
                `Dashboard-menu-button ${disabled ? "disabled " : ""}` +
                /*  (disabled && "disabled") + */
                (!disabled &&
                  (isPending ? "link-pending" : isActive ? "link-active" : ""))
              }
            >
              <IconLarge stroke_color="#1f1f1f" fill="none" icon={option.icon} />
              {only_icon_viewport ? null : option.name}
            </NavLink>
          </motion.li>
        );
      })}
      <motion.li
        variants={li_framer_props.variants}
        style={{ marginTop: "auto" }}
      >
        <NavLink
          to={root_routes.login}
          onClick={handleLogout}
          className={({ isActive, isPending }) =>
            `Dashboard-menu-button ` +
            (isPending ? "link-pending" : isActive ? "link-active" : "")
          }
        >
          <IconLarge
            stroke_color="none"
            fill="none"
            icon={svg_icons.logout_icon}
          />
          {only_icon_viewport ? null : "Sign out"}
        </NavLink>
      </motion.li>
    </motion.ul>
  );
}

export default DashboardMenu;
