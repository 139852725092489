import {
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
  runTransaction,
  increment,
} from "firebase/firestore";
import {
  affiliationsRef,
  getBrandRef,
  getProductRef,
  getStoreRef,
  newProductRef,
  newStoreRef,
  productsRef,
  storesRef,
} from "./fbRefs";
import { timestamps_schema } from "../../schema/common";
import { mapFirebaseDocsDataToObjects } from "./mappers";
import { errors } from "../meta";
import {
  globalCommissionSettingsSchema,
  makeNewAPIIntegrationDetails,
  store_product_schema,
} from "../../schema/affiliate_schema";
import { fb_db } from ".";

export async function createStore({ store }) {
  const store_ref = newStoreRef();
  const brandRef = getBrandRef({ id: store.brand });
  const id = store_ref.id;
  const api_integration = makeNewAPIIntegrationDetails({
    api_key: btoa(id),
    api_key_generated_on: Date.now(),
  });
  const created_store = {
    ...store,
    id,
    api_integration,
  };
  try {
    await setDoc(store_ref, { ...created_store });
    await updateDoc(brandRef, { [`affiliate.store_created`]: Date.now() });
    return created_store;
  } catch (err) {
    console.log(err.message);
    return { error: true, message: err.message };
  }
}

export async function updateStore({ id, link, store_details }) {
  const store_ref = getStoreRef({ id: id });
  const timestamp_updated = timestamps_schema.updated_on;

  try {
    await updateDoc(store_ref, {
      link: link,
      store_details: { ...store_details },
      [timestamp_updated]: Date.now(),
    });
    return { ...store_details, link };
  } catch (err) {
    console.log(err.message);
    return errors.default;
  }
}

export async function updateStoreSettings({ id, settings, old_settings = {} }) {
  const store_ref = getStoreRef({ id: id });
  const settings_timestamp_updated =
    globalCommissionSettingsSchema.timestamps.updated_on;
  const timestamp_updated = timestamps_schema.updated_on;
  const update_date = Date.now();
  try {
    await updateDoc(store_ref, {
      global_settings: { ...settings },
      [settings_timestamp_updated]: update_date,
      [timestamp_updated]: update_date,
      [`settings_history.${update_date}`]: old_settings,
    });
    return settings;
  } catch (err) {
    console.log(err.message);
    return errors.default;
  }
}

export async function fetchStore({ id }) {
  try {
    const store_ref = getStoreRef({ id });
    const store = await getDoc(store_ref);
    return store.data();
  } catch (err) {
    console.log(err.message);
    return errors.default;
  }
}

export async function fetchStorebyBrand({ brand_id }) {
  const store_ref = storesRef();
  const store_query = query(store_ref, where("brand", "==", brand_id));
  try {
    const stores = await getDocs(store_query);
    return stores.docs[0].data();
  } catch (err) {
    console.log(err.message);
    return errors.default;
  }
}

export async function createProduct({ store_id, product }) {
  const product_ref = newProductRef();
  const store_ref = getStoreRef({ id: store_id });
  const id = product_ref.id;
  const product_doc = { ...product, id };
  try {
    const existing_products = await fetchStoreProductWithParam({
      param: "link",
      value: product.link,
    });
    if (existing_products.length)
      return { error: true, message: "Product already exists" };
  } catch (err) {
    console.log(err.message);
    return errors.default;
  }

  try {
    await setDoc(product_ref, product_doc);
    await runTransaction(fb_db, async (transaction) => {
      transaction.update(store_ref, {
        [`counters.total_products`]: increment(1),
      });
    });
    return product_doc;
  } catch (err) {
    console.log(err.message);
    throw new Error(errors.default.message);
  }
}

export async function updateProduct({ product }) {
  const timestamp = Date.now();
  try {
    const product_ref = getProductRef({ id: product.id });
    const updated_product = {
      ...product,
      [timestamps_schema.updated_on]: timestamp,
      [store_product_schema.custom_settings.timestamps.updated_on]: timestamp,
    };
    await updateDoc(product_ref, { ...updated_product });
    return { id: product_ref.id, product: { ...updated_product } };
  } catch (err) {
    console.log(err.message);
    throw new Error(errors.default.message);
  }
}

export async function fetchStoreProducts({ store_id }) {
  const products_ref = productsRef();
  const product_query = query(products_ref, where("store", "==", store_id));
  try {
    const product_docs = await getDocs(product_query);
    const mapped_products = mapFirebaseDocsDataToObjects(product_docs.docs);
    return mapped_products;
  } catch (err) {
    console.log(err.message);
    return errors.default;
  }
}

export async function fetchStoreProductWithParam({ param, value }) {
  const products_ref = productsRef();
  const product_query = query(products_ref, where(param, "==", value));
  try {
    const product_docs = await getDocs(product_query);
    const mapped_products = mapFirebaseDocsDataToObjects(product_docs.docs);
    const docs_length = product_docs.docs.length;
    return {
      products: { ...mapped_products },
      length: docs_length > 0 ? docs_length : null,
    };
  } catch (err) {
    console.log(err.message);
    return errors.default;
  }
}

function groupAffiliations(affiliation_docs) {
  let ungrouped_affiliations = [...affiliation_docs.docs];
  let affiliations_processed = [];
  let grouped_affiliations = [];

  while (ungrouped_affiliations.length > 0) {
    const i = affiliations_processed.length;
    const affiliation = ungrouped_affiliations[0].data();

    affiliations_processed.push(affiliation.actors.affiliate_user);

    grouped_affiliations[i] = ungrouped_affiliations.filter(
      (affiliation_doc) =>
        affiliation_doc.data().actors.affiliate_user ===
        affiliations_processed[i]
    );

    ungrouped_affiliations = ungrouped_affiliations.filter(
      (affiliation_doc) =>
        affiliation_doc.data().actors.affiliate_user !==
        affiliations_processed[i]
    );
  }

  return { affiliations_processed, grouped_affiliations };
}

function sumAffiliationStats(grouped_affiliations) {
  let summed_stats = {
    active: { clicks: 0, income: 0, sales: 0 },
    all: { clicks: 0, income: 0, sales: 0 },
  };
  for (let i = 0; i < grouped_affiliations.length; i++) {
    const { active, all } = grouped_affiliations[i].data().stats;
    summed_stats.active.clicks += active.clicks;
    summed_stats.active.income += active.income;
    summed_stats.active.sales += active.sales;

    summed_stats.all.clicks += all.clicks;
    summed_stats.all.income += all.income;
    summed_stats.all.sales += all.sales;
  }

  return { stats: summed_stats };
}

function mapGroupedAffilaitionsToObj(grouped_affiliations) {
  let final_affiliations = {};
  for (let i = 0; i < grouped_affiliations.length; i++) {
    const {
      actors: { affiliate_user },
      user_profile,
    } = grouped_affiliations[i][0].data();
    let stats = sumAffiliationStats(grouped_affiliations[i]).stats;
    final_affiliations[affiliate_user] = {
      id: affiliate_user,
      user_profile,
      stats,
      total_affiliations: grouped_affiliations[i].length,
      products_affiliated: grouped_affiliations[i]
        .map((aff) => {
          return aff.data().actors.product;
        })
        .filter((product) => product.length !== 0),
    };
  }
  return final_affiliations;
}

export async function fetchStoreAffiliates({ store_id }) {
  const affiliations_ref = affiliationsRef();
  const affiliation_query = query(
    affiliations_ref,
    where("actors.store", "==", store_id),
    where("actors.product", "==", "")
  );

  // let final_affiliations = {};

  try {
    const affiliations_docs = await getDocs(affiliation_query);
    const affiliations = mapFirebaseDocsDataToObjects(affiliations_docs.docs);
    return {
      length: affiliations_docs.docs.length,
      affiliates: { ...affiliations },
    };

    /* let { affiliations_processed, grouped_affiliations } =
      groupAffiliations(affiliations_docs);
    final_affiliations = mapGroupedAffilaitionsToObj(grouped_affiliations);

    const docs_length = affiliations_processed.length;
    return {
      length: docs_length,
      affiliates: { ...final_affiliations },
    }; */
  } catch (err) {
    console.log(err.message);
    return errors.default;
  }
}
