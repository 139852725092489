import { useSelector } from "react-redux";
import {
  SH1,
  SH2,
} from "../../../components/design_system/text/SubHeading";
import { TextIconButtonMedium } from "../../../components/design_system/Buttons";
import Tip from "../../../components/design_system/Tips";
import { svg_icons } from "../../../services/variant_constants";
import { H1 } from "../../../components/design_system/text/Heading";
import { openInNewTab } from "../../../services/common";
import { IconLarge } from "../../../components/design_system/Icons";
import { useNavigate } from "react-router";
import { PopupButton } from "react-calendly";
import { calendly_go_live_meet_link } from "../../../assets/scripts/scriptLinks";
import { motion } from "framer-motion";
import { framer_configs } from "../../../utilty";

function IntegrationOverview() {
  const navigate = useNavigate();
  //const link = useSelector((state) => state.brand_store.link);
  /*  const api_key = useSelector(
    (state) => state.brand_store.api_integration.api_key
  ); */
  const store_id = useSelector((state) => state.brand_store.id);
  const handleGuideLinkOpen = () => {
    openInNewTab("https://drive.google.com/file/d/1pscu9-tM-hYyH3BRW-D0VscLLt_hTsdu/view?usp=sharing")
  }
  const handleWebhookCopy = () => {
    navigator.clipboard.writeText("https://us-central1-socialpi-app.cloudfunctions.net/brand_app/api/affiliate/track_cancellation")
  }

  const handleKeyCopy = () => {
    navigator.clipboard.writeText(`
    const pixel_id = "${btoa(store_id)}"
    function loadScript(scriptUrl) {
      const script = document.createElement('script');
     script.src = scriptUrl;
     document.body.appendChild(script);
     
     return new Promise((res, rej) => {
       script.onload = function() {
         res();
       }
       script.onerror = function () {
         rej();
       }
     });
   }
   
   loadScript('https://cdn.jsdelivr.net/gh/BrandInfluencer/spaf-pixel-shopify@latest/pixelAlpha.js')
     .then(() => {
       console.log('SPAF Initialized..!');
       analytics.subscribe("product_viewed", async (event) => {
     await spaf(pixel_id, spaf_callback_names.product_viewed, event);
   });
   
   analytics.subscribe("product_added_to_cart", async (event) => {
     await spaf(pixel_id, spaf_callback_names.added_to_cart, event);
   });
   
   analytics.subscribe("checkout_started", async (event) => {
     await spaf(pixel_id, spaf_callback_names.start_checkout, event);
   });
   
   analytics.subscribe("checkout_completed", async (event) => {
     await spaf(pixel_id, spaf_callback_names.track_purchase, event);
   
   });
   
   analytics.subscribe("page_viewed", async (event) => {
     await spaf(pixel_id, spaf_callback_names.track_visit, event);
   });
     })
     .catch(() => {
       console.error('SPAF loading failed!');
     });`);
  };

  return (
    <motion.div {...framer_configs.parent_framer_animations.stagger} className="Api-integration-page">
      <div className="Global-affiliate-settings-page-header">
        <IconLarge
          stroke_color="none"
          fill="#1f1f1f"
          icon={svg_icons.left_arrow_icon}
          onClick={() => navigate(-1)}
        />
        <H1>Integrate Callbacks</H1>
      </div>

      <SH2>Follow the steps in our <span onClick={handleGuideLinkOpen} className="Btn-text-link">Integration Guide</span><br />This page has the Pixel Code and the Callback URL you will need in <span className="fw-600">Step 5</span> and <span className="fw-600">Step 8</span> of the guide respectively </SH2>
      {/*    <div className="Api-integration-brief"> */}
      {/* <SH3 classes="fc-pb-60 fw-500">Step 8 of the guide</SH3> */}
      <motion.div variants={{ ...framer_configs.variants.bounce_up }} className="Api-integration-header">
        <SH1 classes="fw-400"><span className="SH3 fc-accent fw-600">Step 5 of the guide</span><br />Shopify Pixel Code {/* <br /><span className="SH3 fc-pb-60 fw-500">Step 5 of the guide</span> */}</SH1>
        <TextIconButtonMedium icon={svg_icons.copy_icon} onClick={handleKeyCopy}>
          Copy
        </TextIconButtonMedium>
      </motion.div>

      {/*  <div className="Integration-code">
          <code>Pixel Id: {btoa(store_id)}</code>
        </div> */}
      {/*  </div> */}

      <motion.div variants={{ ...framer_configs.variants.bounce_up }} className="Api-integration-header">
        <SH1 classes="fw-400"><span className="SH3 fc-accent fw-600">Step 8 of the guide</span><br />Webhook Callback URL</SH1>
        <TextIconButtonMedium icon={svg_icons.copy_icon} onClick={handleWebhookCopy}>
          Copy
        </TextIconButtonMedium>
      </motion.div>

      <motion.div variants={{ ...framer_configs.variants.bounce_up }} className="Api-integration-header">
        <SH1 classes="fw-400"><span className="SH3 fc-accent fw-600">Completed all steps?</span><br />Let's make it Live!</SH1>
        <PopupButton url={calendly_go_live_meet_link} rootElement={document.getElementById("root")} className="Btn Btn-accent Btn-text Btn-text-medium" text="Schedule Go Live Call" />
        {/* <TextButtonMedium>
          Schedule a Go Live Call
        </TextButtonMedium> */}
      </motion.div>

      <Tip header_visible={false} styles={{ container: { maxWidth: "820px" } }} classes={{ container: "grey mt-20" }}>
        <SH2>If you are stuck somewhere, feel free to drop us an email: <span className="fw-600">tech@socialpi.ai</span>{/* reach out to us! <a href="mailto:tech@socialpi.ai" className="Btn-text-link">Contact Us</a> */} </SH2>
      </Tip>

    </motion.div>
  );
}

export default IntegrationOverview;
