import { createContext } from "react";
import { useWindowHeight, useWindowWidth } from "../../hooks/UIHooks";

export const ViewportContext = createContext({
  height: "",
  width: "",
  viewport_type: "",
  is_mobile: "",
});

function ViewportContextProvider({ children }) {
  const viewport_width_params = useWindowWidth();
  const viewport_height_params = useWindowHeight();

  return (
    <ViewportContext.Provider
      value={{ ...viewport_width_params, ...viewport_height_params }}
    >
      {children}
    </ViewportContext.Provider>
  );
}

export default ViewportContextProvider;
