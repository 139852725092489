import { collection, doc } from "firebase/firestore";
import { fb_db } from ".";
import { firebase_collections } from "../meta";

const { users, brands, affiliate_stores, affiliate_products, affiliations } =
  firebase_collections;

export const newUserRef = () => doc(collection(fb_db, users));
export const usersRef = () => collection(fb_db, users);
export const getUserRef = ({ id }) => {
  return doc(fb_db, users, id);
};

export const newBrandRef = () => doc(collection(fb_db, brands));
export const brandsRef = () => collection(fb_db, brands);
export const getBrandRef = ({ id }) => {
  return doc(fb_db, brands, id);
};

export const newStoreRef = () => doc(collection(fb_db, affiliate_stores));
export const storesRef = () => collection(fb_db, affiliate_stores);
export const getStoreRef = ({ id }) => {
  return doc(fb_db, affiliate_stores, id);
};

export const newProductRef = () => doc(collection(fb_db, affiliate_products));
export const productsRef = () => collection(fb_db, affiliate_products);
export const getProductRef = ({ id }) => {
  return doc(fb_db, affiliate_products, id);
};

export const affiliationsRef = () => collection(fb_db, affiliations);
export const getAffiliationRef = ({ id }) => {
  return doc(fb_db, affiliations, id);
};
