import {
  GoogleAuthProvider,
  browserLocalPersistence,
  sendSignInLinkToEmail,
  setPersistence,
  signInWithPopup,
} from "firebase/auth";
import { fb_auth } from ".";
import {
  brandsRef,
  usersRef,
  getBrandRef,
  newUserRef,
  /* newBrandRef, */
} from "./fbRefs";
import {
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
  updateDoc,
} from "firebase/firestore";
import { errors } from "../meta";

const googleProvider = new GoogleAuthProvider();

export const signUpWithGoogle = async () => {
  googleProvider.setCustomParameters({
    prompt: "select_account",
  });
  setPersistence(fb_auth, browserLocalPersistence).then(() => {
    //console.log("State is now persisted");
  });
  try {
    const res = await signInWithPopup(fb_auth, googleProvider);
    const { displayName, photoURL, email, uid, accessToken, refreshToken } =
      res.user;
    return {
      displayName,
      photoURL,
      email,
      uid,
      accessToken,
      refreshToken,
    };
  } catch (err) {
    console.error(err.message);
    return errors.default;
  }
};

export const signUpWithEmail = async ({ email }) => {
  const action_code_settings = {
    url: "http://localhost:3000/login/email_link",
    handleCodeInApp: true,
  };
  sendSignInLinkToEmail(fb_auth, email, action_code_settings)
    .then(() => {
      localStorage.setItem("link_auth_email", email);
    })
    .catch((err) => {
      console.log(err.message);
      return errors.default;
    });
};

export const completeSignUpWithEmail = async ({ email }) => {
  console.log(email);
};

export const findBrandById = async ({ id }) => {
  const brand_ref = getBrandRef({ id: id });
  try {
    const found_brand = await getDoc(brand_ref);
    if (found_brand.exists()) return found_brand.data();
    else return null;
  } catch (err) {
    console.log(err.message);
    return errors.default;
  }
};

export const findBrandByEmail = async ({ email }) => {
  const brand_ref = brandsRef();
  const brand_query = query(brand_ref, where("auth.email.id", "==", email));
  try {
    const found_brand = await getDocs(brand_query);
    if (found_brand.docs.length > 0) return found_brand.docs[0].data();
    else return null;
  } catch (err) {
    console.log(err.message);
    return errors.default;
  }
};

export const findUserByEmail = async ({ email }) => {
  const user_ref = usersRef();
  const user_query = query(user_ref, where("auth.email.id", "==", email));
  try {
    const found_user = await getDocs(user_query);
    if (found_user.docs.length > 0) return found_user.docs[0].data();
    else return null;
  } catch (err) {
    console.log(err.message);
    return errors.default;
  }
};

export const createUser = async ({ user }) => {
  const user_ref = newUserRef();
  const id = user_ref.id;
  const user_doc = { ...user, id };
  try {
    await setDoc(user_ref, user_doc);
    return user_doc;
  } catch (err) {
    console.log(err.message);
    return errors.default;
  }
};

export const createBrand = async ({ brand }) => {
  const brand_ref = getBrandRef({ id: brand.id });

  try {
    await setDoc(brand_ref, brand);
    return { ...brand };
  } catch (err) {
    console.log(err.message);
    return errors.default;
  }
};

export const saveBrandProfile = async ({ brand_id, profile }) => {
  const brand_ref = getBrandRef({ id: brand_id });
  try {
    await updateDoc(brand_ref, { profile: profile });
    return { id: brand_id, profile: { ...profile } };
  } catch (err) {
    console.log(err.message);
    return errors.default;
  }
};
