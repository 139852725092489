import "../styles/Dashboard.css";
import AppMenu from "../components/dashboard/AppMenu";
import { Outlet, useNavigate, useNavigation } from "react-router";
import { useSelector } from "react-redux";
import Banner from "../components/design_system/Banner";
import { api_banner_props, kyc_banner_props } from "./props/appLayoutProps";

function AppLayout({ children, disabled }) {
  const navigate = useNavigate();
  const kyc_completed = useSelector((state) => state.brand.agreement?.signed ?? null)
  const api_integrated = useSelector((state) => state.brand_store.api_integrated)
  const { state } = useNavigation();
  return (
    <div className="App-layout">
      <AppMenu disabled={disabled} />
      <div className="App-outlet">
        {!kyc_completed && <Banner {...kyc_banner_props(navigate)} />}
        {!api_integrated && <Banner {...api_banner_props(navigate)} />}

        {state === "loading" ? <p>Loading...</p> : <Outlet />}
        {children}
      </div>
    </div>
  );
}

export default AppLayout;
