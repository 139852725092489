import { SH2 } from "../text/SubHeading";

export default function ToggleInput({
  children,
  classes,
  styles,
  register = () => {},
  name,
  onClick = () => {},
}) {
  return (
    <div style={styles} className={`Toggle-input ${classes}`}>
      <SH2>{children}</SH2>
      <label className="toggle">
        <input onClick={onClick} type="checkbox" {...register(name)} />
        <span className="slider"></span>
      </label>
    </div>
  );
}

export function ToggleSwitch({
  classes = "",
  styles = {},
  defaultValue = false,
  register = () => {},
  name,
  onClick = () => {},
}) {
  return (
    <div>
      <label style={styles} className={`toggle ${classes}`}>
        <input
          defaultChecked={defaultValue}
          onClick={onClick}
          type="checkbox"
          {...register(name)}
        />
        <span className="slider"></span>
      </label>
    </div>
  );
}
