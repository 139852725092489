import { useSelector } from "react-redux";
import { SH2, SH3 } from "../../design_system/text/SubHeading";
import { affiliate_models_options, commission_types } from "../../../meta";
/* import { ToggleSwitch } from "../../design_system/forms/ToggleInput";
import { store_product_schema } from "../../../schema/affiliate_schema"; */
import { IconLarge } from "../../design_system/Icons";
import { svg_icons } from "../../../services/variant_constants";
import { useNavigate } from "react-router";
import { framer_configs, product_routes } from "../../../utilty";
import { motion } from "framer-motion";
import { useSearchParams } from "react-router-dom";
import { useContext } from "react";
import { ViewportContext } from "../../Providers/ViewportProvider";

function ProductListRow({ product }) {
  const navigate = useNavigate();
  const { is_mobile } = useContext(ViewportContext);

  const commission_settings = useSelector((state) => {
    if (product.is_under_global) return state.brand_store.global_settings;
    return product.custom_settings;
  });

  const [searchParams] = useSearchParams();
  const current_filter = searchParams.get("time") ?? "active";

  const ProductImageAndName = () => {
    return (
      <div onClick={() => {
        navigate(product_routes.product_detail.link + `/${product.id}`);
      }} className="Product">
        <img
          className="Image "
          src={product.product_details.media[0]}
          alt="Product preview"
        />
        <SH2 classes="Title fw-500">{product.product_details.title}</SH2>
        {/*  <P2 classes="fw-500 fc-pb-40 text-underline cursor-pointer">
          {product.counters.total_affiliates} Affiliates
        </P2> */}
      </div>
    );
  };

  const ProductCommissionType = ({ mobile }) => {
    if (!mobile && is_mobile) return null
    return (
      <SH3>{affiliate_models_options[commission_settings.affiliate_model]}</SH3>
    );
  };

  const ProductCommissionRate = ({ mobile }) => {
    if (!mobile && is_mobile) return null
    return (
      <SH3>
        {commission_settings.commission_type ===
          commission_types.fixed_amount && (
            <span className="font-roboto fw-500">₹ </span>
          )}
        {commission_settings.commission_amount}
        {commission_settings.commission_type ===
          commission_types.fixed_percent && "%"}
      </SH3>
    );
  };

  /*   const AffiliateCount = () => {
    return (
      <SH3 classes="fw-500 text-underline">
        {product.counters.total_affiliates.toString()}
      </SH3>
    );
  }; */

  const AffiliateVisits = ({ mobile = false }) => {
    if (!mobile && is_mobile) return null
    return (
      <SH3 classes="fw-500">
        {product?.stats
          ? product?.stats[current_filter]?.clicks?.toString()
          : "0"}
      </SH3>
    );
  };

  const AffiliateSales = ({ mobile = true }) => {
    if (!mobile && is_mobile) return null
    return (
      <SH3 classes="fw-500">
        {product?.stats
          ? product?.stats[current_filter]?.sales?.toString()
          : "0"}
      </SH3>
    );
  };

  const AffiliateRevenue = ({ mobile }) => {
    if (!mobile && is_mobile) return null
    return (
      <SH3 classes="fw-500">
        <span className="font-roboto fw-500">₹ </span>
        {product?.stats
          ? product?.stats[current_filter]?.sales_amount?.toString()
          : "0"}
      </SH3>
    );
  };

  /*   const StatusToggle = () => {
    return (
      <ToggleSwitch
        defaultValue={product.is_affiliate_active}
        name={store_product_schema.is_active}
      />
    );
  }; */

  const ActionToolbar = ({ mobile = false }) => {
    if (!mobile && is_mobile) return null
    return (
      <div className="Toolbar">
        <IconLarge
          onClick={() => {
            navigate(product_routes.product_detail.link + `/${product.id}`);
          }}
          icon={svg_icons.pencil_icon}
        />
        {/*   <IconLarge icon={svg_icons.trash_icon} /> */}
      </div>
    );
  };

  return (
    <motion.li
      variants={{ ...framer_configs.variants.bounce_up }}
      className="Product-listing-row"
    >
      <ProductImageAndName />
      <ProductCommissionType />
      <ProductCommissionRate />
      {/* <AffiliateCount /> */}
      <AffiliateVisits />
      <AffiliateSales />
      <AffiliateRevenue />
      {/* <StatusToggle /> */}
      <ActionToolbar />
    </motion.li>
  );
}

export default ProductListRow;
