import { createSlice } from "@reduxjs/toolkit";
import { makeNewAgreement, makeNewBrandKYC } from "../schema/users_schema";

const initial_state = {
  auth: null,
  id: null,
  profile: null,
  kyc: makeNewBrandKYC({}),
  agreement: makeNewAgreement({}),
  affiliate: {
    store_created: null,
    product_created: null,
    api_integrated: null,
    api_verify_requested: null,
  },
};

const brandSlice = createSlice({
  name: "brand",
  initialState: initial_state,
  reducers: {
    login: (state, action) => {
      return { ...state, ...action.payload };
    },
    brand_logout: () => {
      return initial_state;
    },
    completeProfile: (state, action) => {
      return {
        ...state,
        profile: { ...action.payload, is_profile_completed: true },
      };
    },
    completeStoreCreation: (state) => {
      const store_created = Date.now();
      return {
        ...state,
        affiliate: { ...state.affiliate, store_created },
      };
    },
  },
});

export const { login, brand_logout, completeProfile, completeStoreCreation } =
  brandSlice.actions;
export default brandSlice.reducer;
