import { IconButtonMedium } from "../../../components/design_system/Buttons";
import { svg_icons } from "../../../services/variant_constants";
import { SH1 } from "../../../components/design_system/text/SubHeading";
import { H1 } from "../../../components/design_system/text/Heading";
import { useNavigate } from "react-router";
import { framer_configs, store_routes } from "../../../utilty";
import { motion } from "framer-motion";

function StoreIndex() {
    const navigate = useNavigate();

    const handleSettingsNav = () => {
        navigate(store_routes.basic_details.link);
    };
    const handleAPINav = () => {
        navigate(store_routes.media_kit.link);
    };

    return (
        <motion.div {...framer_configs.animation.bounce_up} className="Settings-page">
            <H1>Settings</H1>

            <motion.div className="Settings-options-container">

                <motion.div {...framer_configs.button_scale_animation} onClick={handleSettingsNav} className="Settings-nav-option">
                    <IconButtonMedium
                        styles={{ width: "70px", height: "70px", backgroundColor: "white" }}
                        icon={svg_icons.picture_icon}
                    />
                    <SH1>Basic Details</SH1>
                </motion.div>

                {/*  <motion.div  {...framer_configs.button_scale_animation} onClick={handleAPINav} className="Settings-nav-option">
                    <IconButtonMedium
                        styles={{ width: "70px", height: "70px", backgroundColor: "white" }}
                        icon={svg_icons.reel_icon}
                        icon_stroke={"none"}
                    />
                    <SH1>Brand Media Kit</SH1>
                </motion.div> */}
            </motion.div>
        </motion.div>
    );
}

export default StoreIndex;
