import { useSelector } from "react-redux";
import { H2 } from "../../design_system/text/Heading";
import Dropdown from "../../design_system/forms/Dropdown";
import { motion } from "framer-motion";
import { framer_configs } from "../../../utilty";
import { useSearchParams } from "react-router-dom";

function DashboardHomeHeader() {
  const store_details = useSelector((state) => state.brand_store.store_details);
  let [searchParams, setSearchParams] = useSearchParams();
  const time_param = searchParams.get("time") ?? "active";
  const handleFilterUpdate = (option) => {
    const new_param = { time: option };
    setSearchParams({ ...searchParams, ...new_param });
  };

  const time_filter_options = [
    { content: "This Month", value: "active" },
    { content: "All time", value: "all" },
  ];

  return (
    <motion.div
      variants={{ ...framer_configs.variants.bounce_up }}
      className="Dashboard-home-header"
    >
      <img
        onClick={handleFilterUpdate}
        className="Store-logo"
        alt="Store Logo"
        src={store_details.logo}
      />
      <H2>{store_details.name}</H2>
      <Dropdown
        onOptionSelect={handleFilterUpdate}
        default_value={
          time_filter_options.filter((option) => option.value === time_param)[0]
        }
        options={time_filter_options}
        name={"time"}
      />
    </motion.div>
  );
}

export default DashboardHomeHeader;
